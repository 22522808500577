//import에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 자세하게 설명해났습니다. 참고하세요.
import React, {useState, useEffect, useRef} from 'react';
//컴포넌트는 src/Homepage/Section/Header.js 파일에 자세하게 설명해났습니다.
//즉 Header파일에 있는 소스를 가져와서
//메인화면에 넣는 다는 것이고 사용 방법은 아래 <Header/>라고 
//import해온 값을 태그걸어서 넣으면 적용이 됩니다.
import Header from '../Section/Header';
import Footer from '../Section/Footer';
import * as H from '../../Styles/StyleComponentHan';
//아래 함수들은 Axios.js 파일을 참고하세요
import {
  get_session_data_homepage,   
  get_guarantee_construction_point_ID,
  get_optimum_film_type,
  get_guarantee_period,
} from "../../Axios";
import { ReactTabulator } from 'react-tabulator';
import guarantee_1 from '../../Images/Guarantee/guarantee_1.jpg';
import guarantee_2 from '../../Images/Guarantee/guarantee_2.jpg';
import guarantee_4 from '../../Images/Guarantee/guarantee_4.jpeg';
import guarantee_ppf_2 from '../../Images/Guarantee/guarantee_ppf_2.jpeg';
//프린트를 사용하기 위한 패키지
import {useReactToPrint} from "react-to-print";
import detail_icon from '../../Images/Etc/detail_icon.png';

function Guarantee() {

  // 프린트 데이터를 담기 위한 useState이며
  // 작업하기 편하라고 기본값 넣어났고 보증서 눌러서
  // 출력하면 기본값은 해당 데이터값으로 치환됩니다.
  const [constructionPointNamePrint, setConstructionPointNamePrint] = useState('예) 시공점명');
  const [constructionPointZipCodePrint, setConstructionPointZipCodePrint] = useState('00000');
  const [constructionPointRoadAddressPrint, setConstructionPointRoadAddressPrint] = useState('광주광역시 OO구 OOO로4');
  const [constructionPointDetailAddressPrint, setConstructionPointDetailAddressPrint] = useState('OOOOOOOOO아파트 000동 000호');
  const [constructionPointPhoneNumberPrint, setConstructionPointPhoneNumberPrint] = useState('051-000-0000');
  const [constructionPointRepresentativePrint, setConstructionPointRepresentativePrint] = useState('대표자');
  const [guaranteeConstructionDatePrint, setGuaranteeConstructionDatePrint] = useState('2023-00-00');
  const [guaranteeSerialNumberPrint, setGuaranteeSerialNumberPrint] = useState('GT 20230000000000');
  const [guaranteeWindshieldUseOptimumFilmTypeIDPrint, setGuaranteeWindshieldUseOptimumFilmTypeIDPrint] = useState(0);
  const [guaranteeWindshieldUseGuaranteePeriodIDPrint, setGuaranteeWindshieldUseGuaranteePeriodIDPrint] = useState(0);
  const [guaranteeWindshieldLotNumberPrint, setGuaranteeWindshieldLotNumberPrint] = useState('');
  const [guaranteeSideGlass1UseOptimumFilmTypeIDPrint, setGuaranteeSideGlass1UseOptimumFilmTypeIDPrint] = useState(1);
  const [guaranteeSideGlass1UseGuaranteePeriodIDPrint, setGuaranteeSideGlass1UseGuaranteePeriodIDPrint] = useState(1);
  const [guaranteeSideGlass1LotNumberPrint, setGuaranteeSideGlass1LotNumberPrint] = useState('Lot 0000000000');
  const [guaranteeSideGlass2UseOptimumFilmTypeIDPrint, setGuaranteeSideGlass2UseOptimumFilmTypeIDPrint] = useState(2);
  const [guaranteeSideGlass2UseGuaranteePeriodIDPrint, setGuaranteeSideGlass2UseGuaranteePeriodIDPrint] = useState(2);
  const [guaranteeSideGlass2LotNumberPrint, setGuaranteeSideGlass2LotNumberPrint] = useState('Lot 0000000000');
  const [guaranteeSideGlass3UseOptimumFilmTypeIDPrint, setGuaranteeSideGlass3UseOptimumFilmTypeIDPrint] = useState(3);
  const [guaranteeSideGlass3UseGuaranteePeriodIDPrint, setGuaranteeSideGlass3UseGuaranteePeriodIDPrint] = useState(3);
  const [guaranteeSideGlass3LotNumberPrint, setGuaranteeSideGlass3LotNumberPrint] = useState('Lot 0000000000');
  const [guaranteeRearGlassUseOptimumFilmTypeIDPrint, setGuaranteeRearGlassUseOptimumFilmTypeIDPrint] = useState(4);
  const [guaranteeRearGlassUseGuaranteePeriodIDPrint, setGuaranteeRearGlassUseGuaranteePeriodIDPrint] = useState(1);
  const [guaranteeRearGlassLotNumberPrint, setGuaranteeRearGlassLotNumberPrint] = useState('Lot 0000000000');
  const [guaranteeRoofGlassUseOptimumFilmTypeIDPrint, setGuaranteeRoofGlassUseOptimumFilmTypeIDPrint] = useState(5);
  const [guaranteeRoofGlassUseGuaranteePeriodIDPrint, setGuaranteeRoofGlassUseGuaranteePeriodIDPrint] = useState(2);
  const [guaranteeRoofGlassLotNumberPrint, setGuaranteeRoofGlassLotNumberPrint] = useState('Lot 0000000000');
  const [guaranteeSearchNamePrint, setGuaranteeCustomerNamePrint] = useState('김OO');
  const [guaranteeCustomerPhoneNumberPrint, setGuaranteeCustomerPhoneNumberPrint] = useState('010-0000-0000');
  const [guaranteeCarTypePrint, setGuaranteeCarTypePrint] = useState('소나타');
  const [guaranteeCarNumberPrint, setGuaranteeCarNumberPrint] = useState('000가 0000'); 
  const [guaranteeMemoPrint, setGuaranteeMemoPrint] = useState('루프선팅 중 흠집발생하여 1회 무료 선팅권 부여');
  const [guiaranteeConstructionLocation, setGuiaranteeConstructionLocation] = useState('광주광역시 수완동 호반 베르디움1차 베란다, 안방,거실');
  const [guiaranteeConstructionFilm, setGuiaranteeConstructionFilm] = useState('DR 25%');
  const [guaranteeVehicleDivision, setGuaranteeVehicleDivision] = useState(1);

  //검색 데이터
  const [guaranteeSearchName, setGuaranteeSearchName] = useState('');

  //옵티멈필름 및 보증기간 배열 데이터
  const [optimumFilmTypeArray, setOptimumFilmTypeArray] = useState([]);
  const [guaranteePeriodArray, setGuaranteePeriodArray] = useState([]);

  // useRef는 React에서 제공하는 hook인데 밑에 HTML을 참고하기 위해 useRef를 사용합니다.
  // printDivRef이거는 아래 프린트를 하기 위한 div를 참고하기 위해 사용했습니다.
  const printDivRef = useRef();
  const [printBackgrounStatus, setPrintBackgrounStatus] = useState(true);


  // useRef는 React에서 제공하는 hook인데 밑에 HTML을 참고하기 위해 useRef를 사용합니다.
  // tabulatorRef이거는 아래 <ReactTabulator> 이거를  참고하기 위해 사용했으며
  // <ReactTabulator> 가면 tabulatorRef를 사용한것을 볼 수 있습니다.
  const tabulatorRef = useRef();

  // useState는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Login/AdminLogin.js파일에 설명을 해났음
  // 참고로 useState([])이렇게 하면 처음 기본값은 []빈배열이라는 것이고 초기값도 저안에 값을 어떻게 하냐에 따라 다릅니다.
  const [guaranteeData, setGuaranteeData] = useState([]); //백엔드에서 가져온 보증서 데이터를 담기위한 useState

  //프린트 작동 함수
  const handlePrint = useReactToPrint({
    content: () => printDivRef.current,
  });

  // 테이블에 사용하는 아이콘
  const detailIcon = function(){
      return `
  <img 
    src=${detail_icon} 
    alt="디테일 이미지"
    style="width:15px;heihgt:15px"
  />`
  };

  const setReadyPrint = (cell) =>{
    setConstructionPointNamePrint(cell.getRow().getData().construction_point_name);
    setConstructionPointZipCodePrint(cell.getRow().getData().construction_point_zip_code);
    setConstructionPointRoadAddressPrint(cell.getRow().getData().construction_point_road_address);
    setConstructionPointDetailAddressPrint(cell.getRow().getData().construction_point_detail_address);
    setConstructionPointPhoneNumberPrint(cell.getRow().getData().construction_point_phone_number);
    setConstructionPointRepresentativePrint(cell.getRow().getData().construction_point_representative);
    setGuaranteeConstructionDatePrint(cell.getRow().getData().guarantee_construction_date);
    setGuaranteeSerialNumberPrint(cell.getRow().getData().guarantee_serial_number);
    setGuaranteeWindshieldUseOptimumFilmTypeIDPrint(cell.getRow().getData().guarantee_windshield_use_optimum_film_type_ID);
    setGuaranteeWindshieldUseGuaranteePeriodIDPrint(cell.getRow().getData().guarantee_windshield_use_guarantee_period_ID);
    setGuaranteeWindshieldLotNumberPrint(cell.getRow().getData().guarantee_windshield_lot_number);
    setGuaranteeSideGlass1UseOptimumFilmTypeIDPrint(cell.getRow().getData().guarantee_side_glass_1_use_optimum_film_type_ID);
    setGuaranteeSideGlass1UseGuaranteePeriodIDPrint(cell.getRow().getData().guarantee_side_glass_1_use_guarantee_period_ID);
    setGuaranteeSideGlass1LotNumberPrint(cell.getRow().getData().guarantee_side_glass_1_lot_number);
    setGuaranteeSideGlass2UseOptimumFilmTypeIDPrint(cell.getRow().getData().guarantee_side_glass_2_use_optimum_film_type_ID);
    setGuaranteeSideGlass2UseGuaranteePeriodIDPrint(cell.getRow().getData().guarantee_side_glass_2_use_guarantee_period_ID);
    setGuaranteeSideGlass2LotNumberPrint(cell.getRow().getData().guarantee_side_glass_2_lot_number);
    setGuaranteeSideGlass3UseOptimumFilmTypeIDPrint(cell.getRow().getData().guarantee_side_glass_3_use_optimum_film_type_ID);
    setGuaranteeSideGlass3UseGuaranteePeriodIDPrint(cell.getRow().getData().guarantee_side_glass_3_use_guarantee_period_ID);
    setGuaranteeSideGlass3LotNumberPrint(cell.getRow().getData().guarantee_side_glass_3_lot_number);
    setGuaranteeRearGlassUseOptimumFilmTypeIDPrint(cell.getRow().getData().guarantee_rear_glass_use_optimum_film_type_ID);
    setGuaranteeRearGlassUseGuaranteePeriodIDPrint(cell.getRow().getData().guarantee_rear_glass_use_guarantee_period_ID);
    setGuaranteeRearGlassLotNumberPrint(cell.getRow().getData().guarantee_rear_glass_lot_number);
    setGuaranteeRoofGlassUseOptimumFilmTypeIDPrint(cell.getRow().getData().guarantee_roof_glass_use_optimum_film_type_ID);
    setGuaranteeRoofGlassUseGuaranteePeriodIDPrint(cell.getRow().getData().guarantee_roof_glass_use_guarantee_period_ID);
    setGuaranteeRoofGlassLotNumberPrint(cell.getRow().getData().guarantee_roof_glass_lot_number);
    setGuaranteeCustomerNamePrint(cell.getRow().getData().guarantee_customer_name);
    setGuaranteeCustomerPhoneNumberPrint(cell.getRow().getData().guarantee_customer_phone_number);
    setGuaranteeCarTypePrint(cell.getRow().getData().guarantee_car_type);
    setGuaranteeCarNumberPrint(cell.getRow().getData().guarantee_car_number);
    setGuaranteeMemoPrint(cell.getRow().getData().guarantee_memo);
    setGuiaranteeConstructionLocation(cell.getRow().getData().guiarantee_construction_location);
    setGuiaranteeConstructionFilm(cell.getRow().getData().guiarantee_construction_film);
    setGuaranteeVehicleDivision(cell.getRow().getData().guarantee_vehicle_division);
  }

  // useEffect는 React에서 제공하는 hook인데 
  // hook에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 설명을 해났음
  // useEffect라는 hook은 앱이 처음실행했을 시 딱 한번만 실행할 수 있는 자바스크립트만
  // 담으면 됩니다.
  // 왜 필요하면 react는 useState를 사용하게 되면 랜더링을 하게되는데
  // useState에 대한 설명은  src/Admin/Login/AdminLogin.js 파일에 설명을 해났음
  // 랜더링이라는 것은 다시한번 소스코드를 읽는다는 건데요.
  // 근데 useEffect안에 있는 자바스크립트는 소스코드를 읽지않습니다.
  // 그래서 필요한겁니다. 
  // 랜더링 될때마다 세션체크는 할필요가 없는거잖아요.
  useEffect(() => {
    //백엔드 서버에 세션이 있는지 없는지 여부에 따라 로그인을 해야되는지 말아야되는지 판단하는 함수
    get_session_data_homepage().then((result)=>{
      if(result.construction_point_ID === '' || result.construction_point_ID === undefined){
        alert('로그인을 해주세요.');
        window.location.href = `/Login`;
      }else{
        setGuaranteeData([]);
        //이거는 데이터베이스에 있는 보증서들을 가져오기 위한 Function이고 
        //자세한 내용은 src/Axios.js 파일 확인
        get_guarantee_construction_point_ID(
          result.construction_point_ID,
          guaranteeSearchName,
        ).then(result_data=>{

          //옵티멈필름 종류들을 가져오는 API 자세한 내용은 src/Axios.js 파일 확인
          get_optimum_film_type().then((result)=>{
            setOptimumFilmTypeArray(result);
          });
  
          //보증기간을 가져오는 API 자세한 내용은 src/Axios.js 파일 확인
          get_guarantee_period().then((result)=>{
            setGuaranteePeriodArray(result);
            //보증서 데이터를 setGuaranteeData를 활용하여 guaranteeData에 할당
            //만약에 보증서 데이터를 어떻게 가져왔나를 확인하고 싶으면
            setGuaranteeData(result_data);
          });
        })
      }
    })
  },[guaranteeSearchName]);
  
  return (
    <>
    {/* 헤더는 src/Homepage/Section/Header.js에서 수정하면 됨 */}
    <Header/>
    {/* <H.printNonViewBox>
      보증서 발급은 PC에서만 확인 가능합니다.
    </H.printNonViewBox> */}

    <H.main_sectionBox className="printNonViewWrap">
      <div className="main_sectionBoxInner">
        {/* <H.printMainTitBox>
          <h1 className="printMainTit">보증서 발급</h1>
          <p className="printMainSubTit">* 보증서 발급은 PC에서만 가능합니다.</p>
        </H.printMainTitBox> */}

        <H.printTopBarWrap>
          <div className="guaranteePrintTopBarInner">
            <H.guranteePrintTopBarSearchbox>
              <p className="printTopBarTit">| 보증서 발급을 위해 우측 버튼을 눌러주세요.</p>
              <div>
                <H.printTopBarSearchBtn className="printTopBarSearchBtn_search"
                  //onClick event에 대한 내용은 src/Admin/Login.js 참고
                  onClick={()=>{window.location.href = "/GuaranteeAdd";}}>등록
                </H.printTopBarSearchBtn>
              </div>
            </H.guranteePrintTopBarSearchbox>
            <H.validatePrintTopBarSearchbox>
              <div>
                <div className="printTopBarSearchLabel">
                  {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
                  <label htmlFor='guarantee_search_name'>검색</label>
                  <input 
                    type='text' 
                    id = 'guarantee_search_name'
                    maxLength={20} 
                    //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                    onChange={(event_data)=>{
                      setGuaranteeSearchName(event_data.target.value);
                    }} 
                    placeholder = "검색할 내용을 입력해 주세요."
                    value={guaranteeSearchName}
                  />
                </div>
              </div>
            </H.validatePrintTopBarSearchbox>
          </div>
          
          {
          guaranteeData.length !== 0 ? 
          <ReactTabulator
            style = {{width:"960px", overflowY:"scroll", margin:"0 auto"}}
            onRef={(r) => (tabulatorRef.current = r.current)}
            data={guaranteeData}
            //밑에 있는 colums는 tabulator에서 제공하는 형식에 맞춰서 컬럼들을 정의하는 겁니다.
            //자세한 내용은 https://tabulator.info/ 공식홈페이지 참고하시면 됩니다.
            columns={[
              {formatter:"rowSelection", titleFormatter:"rowSelection", headerSort:false},
              { title: "ID", field: "guarantee_ID", headerSort:false},
              { title: "수정", formatter:detailIcon, hozAlign:"center", headerSort:false,
              cellClick:function(e, cell){
                window.location.href = `/GuaranteeAdd?guarantee_ID=${cell.getRow().getData().guarantee_ID}`;
              }},
              { title: "미리보기", formatter:detailIcon, hozAlign:"center", headerSort:false,
              cellClick:function(e, cell){
                setReadyPrint(cell);
                setPrintBackgrounStatus(true);
                window.scrollBy(0, 800);
              }},
              { title: "출력(배경O)", formatter:detailIcon, hozAlign:"center", headerSort:false,
              cellClick:function(e, cell){
                setReadyPrint(cell);
                setPrintBackgrounStatus(true);
                setTimeout(() => {
                  handlePrint();
                }, 2000);
              }},
              { title: "출력(배경X)", formatter:detailIcon, hozAlign:"center", headerSort:false,
              cellClick:function(e, cell){
                setReadyPrint(cell);
                setPrintBackgrounStatus(false);
                setTimeout(() => {
                  handlePrint();
                }, 2000);
              }},
              { title: "시공 일자", field: "guarantee_construction_date", headerSort:false},
              { title: "보증서 구분", field: "guarantee_vehicle_division_name", headerSort:false},
              { title: "차종", field: "guarantee_car_type", headerSort:false},
              { title: "차량번호", field: "guarantee_car_number", headerSort:false},
              { title: "시공필름", field: "guiarantee_construction_film", headerSort:false},
              { title: "시공위치", field: "guiarantee_construction_location", headerSort:false},
              { title: "고객명", field: "guarantee_customer_name", headerSort:false},
              { title: "고객전화번호", field: "guarantee_customer_phone_number", headerSort:false},
              { title: "후기", field: "guarantee_review", headerSort:false},
              { title: "메모", field: "guarantee_memo", headerSort:false},
              { title: "등록 일자", field: "guarantee_registe", headerSort:false},
              { title: "수정 일자", field: "guarantee_modified", headerSort:false},
              /* 프린트 출력을 위한 데이터이며 visible:false를 통해 표에는 표시안됨 */
              { title: "보증서 구분 숫자", field: "guarantee_vehicle_division", visible:false},
              { title: "시공점명", field: "construction_point_name", visible:false},
              { title: "시공점 우편번호", field: "construction_point_zip_code", visible:false},
              { title: "시공점 도로명주소", field: "construction_point_road_address", visible:false},
              { title: "시공점 상세주소", field: "construction_point_detail_address", visible:false},
              { title: "대표자", field: "construction_point_representative", visible:false},
              { title: "시공점 연락처", field: "construction_point_phone_number", visible:false},
              { title: "Serial No", field: "guarantee_serial_number", visible:false},
              { title: "전면유리필름종류", field: "guarantee_windshield_use_optimum_film_type_ID", visible:false},
              { title: "전면유리보증기간", field: "guarantee_windshield_use_guarantee_period_ID", visible:false},
              { title: "전면유리LotNo", field: "guarantee_windshield_lot_number", visible:false},
              { title: "측면유리1필름종류", field: "guarantee_side_glass_1_use_optimum_film_type_ID", visible:false},
              { title: "측면유리1보증기간", field: "guarantee_side_glass_1_use_guarantee_period_ID", visible:false},
              { title: "측면유리1LotNo", field: "guarantee_side_glass_1_lot_number", visible:false},
              { title: "측면유리2필름종류", field: "guarantee_side_glass_2_use_optimum_film_type_ID", visible:false},
              { title: "측면유리2보증기간", field: "guarantee_side_glass_2_use_guarantee_period_ID", visible:false},
              { title: "측면유리2LotNo", field: "guarantee_side_glass_2_lot_number", visible:false},
              { title: "측면유리3필름종류", field: "guarantee_side_glass_3_use_optimum_film_type_ID", visible:false},
              { title: "측면유리3보증기간", field: "guarantee_side_glass_3_use_guarantee_period_ID", visible:false},
              { title: "측면유리3LotNo", field: "guarantee_side_glass_3_lot_number", visible:false},
              { title: "후면유리필름종류", field: "guarantee_rear_glass_use_optimum_film_type_ID", visible:false},
              { title: "후면유리보증기간", field: "guarantee_rear_glass_use_guarantee_period_ID", visible:false},
              { title: "후면유리LotNo", field: "guarantee_rear_glass_lot_number", visible:false},
              { title: "루프유리필름종류", field: "guarantee_roof_glass_use_optimum_film_type_ID", visible:false},
              { title: "루프유리보증기간", field: "guarantee_roof_glass_use_guarantee_period_ID", visible:false},
              { title: "루프유리LotNo", field: "guarantee_roof_glass_lot_number", visible:false},
              { title: "시공차종", field: "guarantee_car_type", visible:false},
              { title: "차량번호", field: "guarantee_car_number", visible:false},
            ]}
            options={{ 
              paginationSize:10,
              pagination:'local',
              paginationButtonCount:5, // 밑에 표시될 페이지 갯수
            }}
            layout={"fitData"}
            />
          : 
          <div>
            <p className="guaranteeNodata">데이터가 없습니다.</p>  
          </div>
          }
        </H.printTopBarWrap>
        <div className="guaranteePrintWrap">
          <p className="PrintWrapTit">|&nbsp;&nbsp; 보증서 미리보기 &nbsp;&nbsp;|</p>
          <div 
            className="guaranteePrintWrapInner" 
          >
            {/* 
            - 보증서 HTML 영역입니다.
            - 일단 보증서에 필요한 데이터는 p로 넣어 놓을게요. 
            - 데이터들을 position잡아서 배치시켜 주세요. 
            - 그리고 display:none처리하면 프린트 안되니깐 position으로 숨겨야 합니다.
            */}
            <div ref={printDivRef} className="guaranteePrintBox">
              <p className="guatanteeImg">
                {printBackgrounStatus && guaranteeVehicleDivision === 1 && 
                <img src={guarantee_1} alt="보증서화면1"/>}
                {printBackgrounStatus && guaranteeVehicleDivision === 2 && 
                <img src={guarantee_4} alt="보증서화면2"/>}
                {printBackgrounStatus && guaranteeVehicleDivision === 3 && 
                <img src={guarantee_ppf_2} alt="보증서화면_PPF"/>}
              </p>


              {guaranteeVehicleDivision === 1 && 
              <>
              <p className="guaranteeP_pointName">{constructionPointNamePrint}</p>{/* 시공점명 */}
              <p className="guaranteeP_address">({constructionPointZipCodePrint}) {constructionPointRoadAddressPrint} {constructionPointDetailAddressPrint}</p>{/* 주소 */}
              <p className="guaranteeP_representative">{constructionPointRepresentativePrint}</p>{/* 대표자 */}
              <p className="guaranteeP_phoneNum">{constructionPointPhoneNumberPrint}</p>{/* 시공점 연락처 */}
              {/* 시공자는 수기로 작성하는거고 시공 웹사이트는 현재 개발중인 옵티멈 주소 www.optimumwindowfilm.com 똑같이 넣으면 되요*/}
              <p className="guaranteeP_date">{guaranteeConstructionDatePrint}</p>{/* 시공일자 */}
              <p className="guaranteeP_serialNum">{guaranteeSerialNumberPrint}</p>{/* Serial No */}

              {/* 전면유리 Section */}
              {/* 
                - 아래 div 시공을 했는지 안했는지 여부에 따라 시공을 했으면 check_fill이라는 클래스명을 입력하게 했어요.
                - 나중에 작업하실 때는 클래스명 바꾸셔서 사용하시면 되요. 
              */}
              <div className="guaranteeP_windowFront">
                <div className={guaranteeWindshieldUseOptimumFilmTypeIDPrint !== 0 ? 'check_fill' : ''}></div>
                {/* 제품명 */}
                {guaranteeWindshieldUseOptimumFilmTypeIDPrint !== 0 && optimumFilmTypeArray.map(
                  (element, index) => 
                    guaranteeWindshieldUseOptimumFilmTypeIDPrint === element.optimum_film_type_ID && (
                      <p key={index}>
                        {element.optimum_film_type_name} {element.optimum_film_type_density}
                      </p>
                    ),
                )}
                <p>{guaranteeWindshieldLotNumberPrint}</p>{/* Lot No */}
                {/* 보증기간 */}
                {guaranteeWindshieldUseGuaranteePeriodIDPrint !== 0 && guaranteePeriodArray.map(
                  (element, index) => 
                    guaranteeWindshieldUseGuaranteePeriodIDPrint === element.guarantee_period_ID && (
                      <p key={index}>
                        {element.guarantee_period_year}
                      </p>
                    ),
                )}
              </div>

              {/* 측면유리1 Section */}
              {/* 
                - 아래 div 시공을 했는지 안했는지 여부에 따라 시공을 했으면 check_fill이라는 클래스명을 입력하게 했어요.
                - 나중에 작업하실 때는 클래스명 바꾸셔서 사용하시면 되요. 
              */}
              <div className="guaranteeP_windowSide1">
                <div className={guaranteeSideGlass1UseOptimumFilmTypeIDPrint !== 0 ? 'check_fill' : ''}></div>
                {/* 제품명 */}
                {guaranteeSideGlass1UseOptimumFilmTypeIDPrint !== 0 && optimumFilmTypeArray.map(
                  (element, index) => 
                  guaranteeSideGlass1UseOptimumFilmTypeIDPrint === element.optimum_film_type_ID && (
                      <p key={index}>
                        {element.optimum_film_type_name} {element.optimum_film_type_density}
                      </p>
                    ),
                )}
                <p>{guaranteeSideGlass1LotNumberPrint}</p>{/* Lot No */}
                {/* 보증기간 */}
                {guaranteeSideGlass1UseGuaranteePeriodIDPrint !== 0 && guaranteePeriodArray.map(
                  (element, index) => 
                  guaranteeSideGlass1UseGuaranteePeriodIDPrint === element.guarantee_period_ID && (
                      <p key={index}>
                        {element.guarantee_period_year}
                      </p>
                    ),
                )}
              </div>

              {/* 측면유리2 Section */}
              {/* 
                - 아래 div 시공을 했는지 안했는지 여부에 따라 시공을 했으면 check_fill이라는 클래스명을 입력하게 했어요.
                - 나중에 작업하실 때는 클래스명 바꾸셔서 사용하시면 되요. 
              */}
              <div className="guaranteeP_windowSide2">
                <div className={guaranteeSideGlass2UseOptimumFilmTypeIDPrint !== 0 ? 'check_fill' : ''}></div>
                {/* 제품명 */}
                {guaranteeSideGlass2UseOptimumFilmTypeIDPrint !== 0 && optimumFilmTypeArray.map(
                  (element, index) => 
                  guaranteeSideGlass2UseOptimumFilmTypeIDPrint === element.optimum_film_type_ID && (
                      <p key={index}>
                        {element.optimum_film_type_name} {element.optimum_film_type_density}
                      </p>
                    ),
                )}
                <p>{guaranteeSideGlass2LotNumberPrint}</p>{/* Lot No */}
                {/* 보증기간 */}
                {guaranteeSideGlass2UseGuaranteePeriodIDPrint !== 0 && guaranteePeriodArray.map(
                  (element, index) => 
                  guaranteeSideGlass2UseGuaranteePeriodIDPrint === element.guarantee_period_ID && (
                      <p key={index}>
                        {element.guarantee_period_year}
                      </p>
                    ),
                )}
              </div>

              {/* 측면유리3 Section */}
              {/* 
                - 아래 div 시공을 했는지 안했는지 여부에 따라 시공을 했으면 check_fill이라는 클래스명을 입력하게 했어요.
                - 나중에 작업하실 때는 클래스명 바꾸셔서 사용하시면 되요. 
              */}
              <div className="guaranteeP_windowSide3">
                <div className={guaranteeSideGlass3UseOptimumFilmTypeIDPrint !== 0 ? 'check_fill' : ''}></div>
                {/* 제품명 */}
                {guaranteeSideGlass3UseOptimumFilmTypeIDPrint !== 0 && optimumFilmTypeArray.map(
                  (element, index) => 
                  guaranteeSideGlass3UseOptimumFilmTypeIDPrint === element.optimum_film_type_ID && (
                      <p key={index}>
                        {element.optimum_film_type_name} {element.optimum_film_type_density}
                      </p>
                    ),
                )}
                <p>{guaranteeSideGlass3LotNumberPrint}</p>{/* Lot No */}
                {/* 보증기간 */}
                {guaranteeSideGlass3UseGuaranteePeriodIDPrint !== 0 && guaranteePeriodArray.map(
                  (element, index) => 
                  guaranteeSideGlass3UseGuaranteePeriodIDPrint === element.guarantee_period_ID && (
                      <p key={index}>
                        {element.guarantee_period_year}
                      </p>
                    ),
                )}
              </div>

              {/* 후면유리 Section */}
              {/* 
                - 아래 div 시공을 했는지 안했는지 여부에 따라 시공을 했으면 check_fill이라는 클래스명을 입력하게 했어요.
                - 나중에 작업하실 때는 클래스명 바꾸셔서 사용하시면 되요. 
              */}
              <div className="guaranteeP_windowBack">
                <div className={guaranteeRearGlassUseOptimumFilmTypeIDPrint !== 0 ? 'check_fill' : ''}></div>
                {/* 제품명 */}
                {guaranteeRearGlassUseOptimumFilmTypeIDPrint !== 0 && optimumFilmTypeArray.map(
                  (element, index) => 
                  guaranteeRearGlassUseOptimumFilmTypeIDPrint === element.optimum_film_type_ID && (
                      <p key={index}>
                        {element.optimum_film_type_name} {element.optimum_film_type_density}
                      </p>
                    ),
                )}
                <p>{guaranteeRearGlassLotNumberPrint}</p>{/* Lot No */}
                {/* 보증기간 */}
                {guaranteeRearGlassUseGuaranteePeriodIDPrint !== 0 && guaranteePeriodArray.map(
                  (element, index) => 
                  guaranteeRearGlassUseGuaranteePeriodIDPrint === element.guarantee_period_ID && (
                      <p key={index}>
                        {element.guarantee_period_year}
                      </p>
                    ),
                )}
              </div>

              {/* 루프유리 Section */}
              {/* 
                - 아래 div 시공을 했는지 안했는지 여부에 따라 시공을 했으면 check_fill이라는 클래스명을 입력하게 했어요.
                - 나중에 작업하실 때는 클래스명 바꾸셔서 사용하시면 되요. 
              */}
              <div className="guaranteeP_windowRoof">
                <div className={guaranteeRoofGlassUseOptimumFilmTypeIDPrint !== 0 ? 'check_fill' : ''}></div>
                {/* 제품명 */}
                {guaranteeRoofGlassUseOptimumFilmTypeIDPrint !== 0 && optimumFilmTypeArray.map(
                  (element, index) => 
                  guaranteeRoofGlassUseOptimumFilmTypeIDPrint === element.optimum_film_type_ID && (
                      <p key={index}>
                        {element.optimum_film_type_name} {element.optimum_film_type_density}
                      </p>
                    ),
                )}
                <p>{guaranteeRoofGlassLotNumberPrint}</p>{/* Lot No */}
                {/* 보증기간 */}
                {guaranteeRoofGlassUseGuaranteePeriodIDPrint !== 0 && guaranteePeriodArray.map(
                  (element, index) => 
                  guaranteeRoofGlassUseGuaranteePeriodIDPrint === element.guarantee_period_ID && (
                      <p key={index}>
                        {element.guarantee_period_year}
                      </p>
                    ),
                )}
              </div>

              <p className="guaranteeP_customerName">{guaranteeSearchNamePrint}</p>{/* 고객명 */}
              <p className="guaranteeP_customerPhoneNum">{guaranteeCustomerPhoneNumberPrint}</p>{/* 연락처 */}
              <p className="guaranteeP_carType">{guaranteeCarTypePrint}</p>{/* 시공차종 */}
              <p className="guaranteeP_carNum">{guaranteeCarNumberPrint}</p>{/* 차량번호 */}
              <p className="guaranteeP_memo">{guaranteeMemoPrint}</p>{/* 메모 */}
              </>
              }

              {(guaranteeVehicleDivision === 2 || guaranteeVehicleDivision === 3) && 
              <>
              <p className="guaranteeP_pointName_2">{constructionPointNamePrint}</p>{/* 시공점명 */}
              <p className="guaranteeP_address_2">({constructionPointZipCodePrint}) {constructionPointRoadAddressPrint} {constructionPointDetailAddressPrint}</p>{/* 주소 */}
              <p className="guaranteeP_representative_2">{constructionPointRepresentativePrint}</p>{/* 대표자 */}
              <p className="guaranteeP_phoneNum_2">{constructionPointPhoneNumberPrint}</p>{/* 시공점 연락처 */}

              <p className="guaranteeP_serialNum_2">{guaranteeSerialNumberPrint}</p>{/* Serial No */}
              <p className="guaranteeP_date_2">{guaranteeConstructionDatePrint}</p>{/* 시공일자 */}
              <p className="guaranteeP_location_2">{guiaranteeConstructionLocation}</p>{/* 시공일자 */}
              <p className="guaranteeP_film_2">{guiaranteeConstructionFilm}</p>{/* 시공일자 */}


              <p className="guaranteeP_customerName_2">{guaranteeSearchNamePrint}</p>{/* 고객명 */}
              <p className="guaranteeP_customerPhoneNum_2">{guaranteeCustomerPhoneNumberPrint}</p>{/* 연락처 */}
              <p className="guaranteeP_memo_2">{guaranteeMemoPrint}</p>{/* 메모 */}
              </>
              }

              {printBackgrounStatus && 
              <p className="guatanteeImg02">
                <img src={guarantee_2} alt="보증서화면2"/>
              </p>
              }
              
            </div>
          </div>
        </div>
      </div>
    </H.main_sectionBox>
    <Footer/>
    </>
  );
}

export default Guarantee;
