//import에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 자세하게 설명해났습니다. 참고하세요.
import React, {useState, useRef, useEffect} from 'react';
//컴포넌트는 src/Homepage/Section/Header.js 파일에 자세하게 설명해났습니다.
//즉 Header파일에 있는 소스를 가져와서
//메인화면에 넣는 다는 것이고 사용 방법은 아래 <Header/>라고 
//import해온 값을 태그걸어서 넣으면 적용이 됩니다.
import Header from '../Section/Header';
import Footer from '../Section/Footer';
import {
  get_guarantee_customer,
  get_optimum_film_type,
} from "../../Axios";
import * as H from '../../Styles/StyleComponentHan';
import {
  removeWhitespacePhone,
  validatePhone,
} from '../../Validation';
import certificate_of_authenticity from '../../Images/CertificateOfAuthenticity/certificate_of_authenticity.jpg';
import certificate_of_authenticity_mobile from '../../Images/CertificateOfAuthenticity/certificate_of_authenticity_mobile.jpg';
import certificate_of_authenticity_construction_new from '../../Images/CertificateOfAuthenticity/certificate_of_authenticity_construction_new.jpeg';
import certificate_of_authenticity_construction_mobile_new from '../../Images/CertificateOfAuthenticity/certificate_of_authenticity_construction_mobile_new.jpeg';
import certificate_stamp from '../../Images/CertificateOfAuthenticity/certificate_stamp.png';
import {useReactToPrint} from "react-to-print";

export function Validate() {  

  // useRef는 React에서 제공하는 hook인데 밑에 HTML을 참고하기 위해 useRef를 사용합니다.
  // printDivRef이거는 아래 프린트를 하기 위한 div를 참고하기 위해 사용했습니다.
  const printDivRef = useRef();

  // 프린트 데이터를 담기 위한 useState이며
  // 작업하기 편하라고 기본값 넣어났고 보증서 눌러서
  // 출력하면 기본값은 해당 데이터값으로 치환됩니다.
  const [guaranteeReviewStatus, setGuaranteeReviewStatus] = useState();
  const [guaranteeCustomerNamePrint, setGuaranteeCustomerNamePrint] = useState('김OO');
  const [guaranteeCarNumberPrint, setGuaranteeCarNumberPrint] = useState('000가 0000'); 
  const [constructionPointNamePrint, setConstructionPointNamePrint] = useState('옵티멈윈도우필름 본사');
  const [constructionProductPrint, setConstructionProductPrint] = useState('HP 4(전면), HP 39(측면1), HP 39(측면2), HP 14(측면3), HP 14(후면), HP 14(루프)');
  const [guaranteeVehicleDivision, setGuaranteeVehicleDivision] = useState(1);
  const [guiaranteeConstructionLocation, setGuiaranteeConstructionLocation] = useState('광주광역시 수완동 호반 베르디움1차 베란다, 안방,거실');
  const [guiaranteeConstructionFilm, setGuiaranteeConstructionFilm] = useState('DR 25%');

  //useState에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고  
  //검색 데이터
  const [guaranteeCustomerName, setGuaranteeCustomerName] = useState('');
  const [guaranteeCustomerPhoneNumber, setGuaranteeCustomerPhoneNumber] = useState('');

  //에러 출력 데이터
  const [guaranteeCustomerNameError, setGuaranteeCustomerNameError] = useState('');
  const [guaranteeCustomerPhoneNumberError, setGuaranteeCustomerPhoneNumberError] = useState('');

  //옵티멈필름 및 보증기간 배열 데이터
  const [optimumFilmTypeArray, setOptimumFilmTypeArray] = useState([]);
  
  //useState에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고  
  const [guaranteeData, setGuaranteeData] = useState([]); // 검색한 보증서 내용 저장

  //휴대폰 형식 체킹
  const validHcCustomerPhoneNumber = (value)=>{
    setGuaranteeCustomerPhoneNumber(value);
    if(!validatePhone(value)){
      setGuaranteeCustomerPhoneNumberError("휴대폰번호 형식으로 입력해주세요.");
    }else{
      setGuaranteeCustomerPhoneNumberError('');
    }
  }

  //프린트 작동 함수
  const handlePrint = useReactToPrint({
    content: () => printDivRef.current,
  });

  //컨트롤 함수
  const form_check = () =>{
    let continue_add = true;
    //여기는 등록버튼을 눌렀을 시 값을 안넣으면 에러데이터를 뿌려주는 곳입니다.
    //그리고 에러가 발생되면 위에 continue_add를 false로 바꿔서
    if(guaranteeCustomerName === ''){setGuaranteeCustomerNameError('이름을 입력해주세요.'); continue_add = false;}else{setGuaranteeCustomerNameError('');}
    if(guaranteeCustomerPhoneNumber === ''){setGuaranteeCustomerPhoneNumberError('전화번호를 입력해주세요.'); continue_add = false;}else{setGuaranteeCustomerPhoneNumberError('');}

    //아래 false일 경우에는 등록하지 않습니다.
    if(continue_add){
      get_guarantee_customer(
        guaranteeCustomerName,
        guaranteeCustomerPhoneNumber,
      ).then((result)=>{
        if(result.length === 0){
          alert('검색된 데이터가 없습니다. 시공점에 확인해주세요.');
        }else{
          setGuaranteeData(result);
        }
      });
    }else{
      alert('이름 또는 전화번호를 입력해주세요.');
    }
  }
  

  // useEffect는 React에서 제공하는 hook인데 
  // hook에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 설명을 해났음
  useEffect(() => {
    //옵티멈필름 종류들을 가져오는 API 자세한 내용은 src/Axios.js 파일 확인
    get_optimum_film_type().then((result)=>{
      setOptimumFilmTypeArray(result);
    });
      
    },[]);

  return (
    <> 
    {/* 
        - 보증서 HTML 영역입니다.
        - 일단 보증서에 필요한 데이터는 p로 넣어 놓을게요. 
        - 데이터들을 position잡아서 배치시켜 주세요. 
        - 그리고 display:none처리하면 프린트 안되니깐 position으로 숨겨야 합니다.
      */}
      <Header />
      {/* <H.printNonViewBox>
        정품 확인서 출력은 PC에서만 확인 가능합니다.
      </H.printNonViewBox> */}

      <H.main_validateWrap /* className="printNonViewWrap" */>
        <div className="main_validateInner">
          <H.printMainTitBox>
            <h1 className="printMainTit">정품 확인</h1>
            <p className="printMainSubTit">* 정품 확인서 출력은 PC에서만 가능합니다.</p>
          </H.printMainTitBox>

          <H.validatePrintTopBarWrap>
            <div className="printTopBarInner">
              <p className="printTopBarTit">| 정품 확인을 원하는 이름, 전화번호를 검색해 주세요.</p>
              <H.validatePrintTopBarSearchbox>
                <div>
                  <div className="printTopBarSearchLabel">
                    {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
                    <label htmlFor='guarantee_customer_name'>이름<span> * </span></label>
                    <input 
                      type='text' 
                      id = 'guarantee_customer_name'
                      maxLength={20} 
                      //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                      onChange={(event_data)=>{
                        setGuaranteeCustomerName(event_data.target.value);
                      }} 
                      onKeyDown={(event_data)=>{
                        if (event_data.key === 'Enter') {
                          form_check();
                        }
                      }}
                      placeholder = "이름을 입력해 주세요."
                      value={guaranteeCustomerName}
                    />
                    
                  </div>
                  <p className="printTopBarSearchError">{guaranteeCustomerNameError}</p>
                </div>
                <div>
                  <div className="printTopBarSearchLabel">
                    {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
                    <label htmlFor='guarantee_customer_phone_number'>전화번호<span> * </span> </label>
                    <input 
                      type='text' 
                      id = 'guarantee_customer_phone_number' 
                      maxLength={20} 
                      //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                      onChange={(event_data)=>{
                        validHcCustomerPhoneNumber(removeWhitespacePhone(event_data.target.value));
                      }} 
                      onKeyDown={(event_data)=>{
                        if (event_data.key === 'Enter') {
                          form_check();
                        }
                      }}
                      placeholder = "전화번호를 입력해 주세요."
                      value={guaranteeCustomerPhoneNumber}
                    />
                  </div>
                  <p className="printTopBarSearchError">{guaranteeCustomerPhoneNumberError}</p>
                </div>

                <H.printTopBarSearchBtn className="printTopBarSearchBtn_searchValidate"
                  //onClick event에 대한 내용은 src/Admin/Login.js 참고
                  onClick={()=>{form_check(); }}>
                  {/* 보증서 파라미터가 있는지 없는지에 따라 등록인지 수정인지 판단 */}
                  검색
                </H.printTopBarSearchBtn>
              </H.validatePrintTopBarSearchbox>

              {guaranteeData.length !== 0 && guaranteeData.map(
                (element, index) => 
                <H.validatePrintTopBarSearchResult
                  key={index}
                >
                  <div className="printTopBarSearchResult_left">
                    <div>
                      <p>시&nbsp; 공 &nbsp;점</p>
                      <p>{element.construction_point_name}</p>
                    </div>
                    <div>
                      <p>
                        {element.guarantee_vehicle_division === 1 ? '차량번호' : '시공위치'}
                      </p>
                      <p>
                        {element.guarantee_vehicle_division === 1 ? element.guarantee_car_number : element.guiarantee_construction_location}
                      </p>
                    </div>
                    <div>
                      <p>시공제품</p>
                      {element.guarantee_vehicle_division === 1 ? 
                      <p>
                      {' '}
                      {element.guarantee_windshield_use_optimum_film_type_ID !== 0 && optimumFilmTypeArray.map(
                        (element_film, index_film) => 
                          element.guarantee_windshield_use_optimum_film_type_ID === element_film.optimum_film_type_ID && (
                            <span key={index_film}>
                              {element_film.optimum_film_type_name} {element_film.optimum_film_type_density}(전면),{' '}
                            </span>
                          ),
                      )}
                      {element.guarantee_side_glass_1_use_optimum_film_type_ID !== 0 && optimumFilmTypeArray.map(
                        (element_film, index_film) => 
                          element.guarantee_side_glass_1_use_optimum_film_type_ID === element_film.optimum_film_type_ID && (
                            <span key={index_film}>
                              {element_film.optimum_film_type_name} {element_film.optimum_film_type_density}(측면1),{' '}
                            </span>
                          ),
                      )}
                      {element.guarantee_side_glass_2_use_optimum_film_type_ID !== 0 && optimumFilmTypeArray.map(
                        (element_film, index_film) => 
                          element.guarantee_side_glass_2_use_optimum_film_type_ID === element_film.optimum_film_type_ID && (
                            <span key={index_film}>
                              {element_film.optimum_film_type_name} {element_film.optimum_film_type_density}(측면2),{' '}
                            </span>
                          ),
                      )}
                      {element.guarantee_side_glass_3_use_optimum_film_type_ID !== 0 && optimumFilmTypeArray.map(
                        (element_film, index_film) => 
                          element.guarantee_side_glass_3_use_optimum_film_type_ID === element_film.optimum_film_type_ID && (
                            <span key={index_film}>
                              {element_film.optimum_film_type_name} {element_film.optimum_film_type_density}(측면3),{' '}
                            </span>
                          ),
                      )}
                      {element.guarantee_rear_glass_use_optimum_film_type_ID !== 0 && optimumFilmTypeArray.map(
                        (element_film, index_film) => 
                          element.guarantee_rear_glass_use_optimum_film_type_ID === element_film.optimum_film_type_ID && (
                            <span key={index_film}>
                              {element_film.optimum_film_type_name} {element_film.optimum_film_type_density}(후면),{' '}
                            </span>
                          ),
                      )}
                      {element.guarantee_roof_glass_use_optimum_film_type_ID !== 0 && optimumFilmTypeArray.map(
                        (element_film, index_film) => 
                          element.guarantee_roof_glass_use_optimum_film_type_ID === element_film.optimum_film_type_ID && (
                            <span key={index_film}>
                              {element_film.optimum_film_type_name} {element_film.optimum_film_type_density}(루프)
                            </span>
                          ),
                      )}
                      </p>
                      :
                      <p>{element.guiarantee_construction_film}</p>
                      }
                    </div>
                  </div>

                  <H.validatePrintBtnBox>

                    <H.printTopBarSearchBtn className="printTopBarSearchBtn_previewValidate"
                      onClick={()=>{
                        let temp_construction_product_print = '';
                        if(element.guarantee_review){
                          setGuaranteeReviewStatus(true)
                        }else{
                          setGuaranteeReviewStatus(false)
                        }
                        setGuaranteeCustomerNamePrint(element.guarantee_customer_name);
                        setGuaranteeCarNumberPrint(element.guarantee_car_number);
                        setConstructionPointNamePrint(element.construction_point_name);
                        setGuaranteeVehicleDivision(element.guarantee_vehicle_division);
                        setGuiaranteeConstructionLocation(element.guiarantee_construction_location);
                        setGuiaranteeConstructionFilm(element.guiarantee_construction_film);

                        if(element.guarantee_windshield_use_optimum_film_type_ID !== 0){
                          optimumFilmTypeArray.forEach(
                            (element_film) => {
                              if(element.guarantee_windshield_use_optimum_film_type_ID === element_film.optimum_film_type_ID){
                                temp_construction_product_print = temp_construction_product_print + element_film.optimum_film_type_name + element_film.optimum_film_type_density + '(전면), ';
                              }
                            }
                          )
                        }
                        if(element.guarantee_side_glass_1_use_optimum_film_type_ID !== 0){
                          optimumFilmTypeArray.forEach(
                            (element_film) => {
                              if(element.guarantee_side_glass_1_use_optimum_film_type_ID === element_film.optimum_film_type_ID){
                                temp_construction_product_print = temp_construction_product_print + element_film.optimum_film_type_name + element_film.optimum_film_type_density + '(측면1), ';
                              }
                            }
                          )
                        }
                        if(element.guarantee_side_glass_2_use_optimum_film_type_ID !== 0){
                          optimumFilmTypeArray.forEach(
                            (element_film) => {
                              if(element.guarantee_side_glass_2_use_optimum_film_type_ID === element_film.optimum_film_type_ID){
                                temp_construction_product_print = temp_construction_product_print + element_film.optimum_film_type_name + element_film.optimum_film_type_density + '(측면2), ';
                              }
                            }
                          )
                        }
                        if(element.guarantee_side_glass_3_use_optimum_film_type_ID !== 0){
                          optimumFilmTypeArray.forEach(
                            (element_film) => {
                              if(element.guarantee_side_glass_3_use_optimum_film_type_ID === element_film.optimum_film_type_ID){
                                temp_construction_product_print = temp_construction_product_print + element_film.optimum_film_type_name + element_film.optimum_film_type_density + '(측면3), ';
                              }
                            }
                          )
                        }
                        if(element.guarantee_rear_glass_use_optimum_film_type_ID !== 0){
                          optimumFilmTypeArray.forEach(
                            (element_film) => {
                              if(element.guarantee_rear_glass_use_optimum_film_type_ID === element_film.optimum_film_type_ID){
                                temp_construction_product_print = temp_construction_product_print + element_film.optimum_film_type_name + element_film.optimum_film_type_density + '(후면), ';
                              }
                            }
                          )
                        }
                        if(element.guarantee_roof_glass_use_optimum_film_type_ID !== 0){
                          optimumFilmTypeArray.forEach(
                            (element_film) => {
                              if(element.guarantee_roof_glass_use_optimum_film_type_ID === element_film.optimum_film_type_ID){
                                temp_construction_product_print = temp_construction_product_print + element_film.optimum_film_type_name + element_film.optimum_film_type_density + '(루프), ';
                              }
                            }
                          )
                        }
                        setConstructionProductPrint(temp_construction_product_print);
                        window.scrollBy(0, 800);
                      }}
                    >정품확인서<br></br>보기
                    </H.printTopBarSearchBtn>
                    <H.printTopBarSearchBtn className="printTopBarSearchBtn_printValidate"
                      onClick={()=>{
                        let temp_construction_product_print = '';
                        setGuaranteeCustomerNamePrint(element.guarantee_customer_name);
                        setGuaranteeCarNumberPrint(element.guarantee_car_number);
                        setConstructionPointNamePrint(element.construction_point_name);
                        setGuaranteeVehicleDivision(element.guarantee_vehicle_division);
                        setGuiaranteeConstructionLocation(element.guiarantee_construction_location);
                        setGuiaranteeConstructionFilm(element.guiarantee_construction_film);

                        if(element.guarantee_windshield_use_optimum_film_type_ID !== 0){
                          optimumFilmTypeArray.forEach(
                            (element_film) => {
                              if(element.guarantee_windshield_use_optimum_film_type_ID === element_film.optimum_film_type_ID){
                                temp_construction_product_print = temp_construction_product_print + element_film.optimum_film_type_name + element_film.optimum_film_type_density + '(전면), ';
                              }
                            }
                          )
                        }
                        if(element.guarantee_side_glass_1_use_optimum_film_type_ID !== 0){
                          optimumFilmTypeArray.forEach(
                            (element_film) => {
                              if(element.guarantee_side_glass_1_use_optimum_film_type_ID === element_film.optimum_film_type_ID){
                                temp_construction_product_print = temp_construction_product_print + element_film.optimum_film_type_name + element_film.optimum_film_type_density + '(측면1), ';
                              }
                            }
                          )
                        }
                        if(element.guarantee_side_glass_2_use_optimum_film_type_ID !== 0){
                          optimumFilmTypeArray.forEach(
                            (element_film) => {
                              if(element.guarantee_side_glass_2_use_optimum_film_type_ID === element_film.optimum_film_type_ID){
                                temp_construction_product_print = temp_construction_product_print + element_film.optimum_film_type_name + element_film.optimum_film_type_density + '(측면2), ';
                              }
                            }
                          )
                        }
                        if(element.guarantee_side_glass_3_use_optimum_film_type_ID !== 0){
                          optimumFilmTypeArray.forEach(
                            (element_film) => {
                              if(element.guarantee_side_glass_3_use_optimum_film_type_ID === element_film.optimum_film_type_ID){
                                temp_construction_product_print = temp_construction_product_print + element_film.optimum_film_type_name + element_film.optimum_film_type_density + '(측면3), ';
                              }
                            }
                          )
                        }
                        if(element.guarantee_rear_glass_use_optimum_film_type_ID !== 0){
                          optimumFilmTypeArray.forEach(
                            (element_film) => {
                              if(element.guarantee_rear_glass_use_optimum_film_type_ID === element_film.optimum_film_type_ID){
                                temp_construction_product_print = temp_construction_product_print + element_film.optimum_film_type_name + element_film.optimum_film_type_density + '(후면), ';
                              }
                            }
                          )
                        }
                        if(element.guarantee_roof_glass_use_optimum_film_type_ID !== 0){
                          optimumFilmTypeArray.forEach(
                            (element_film) => {
                              if(element.guarantee_roof_glass_use_optimum_film_type_ID === element_film.optimum_film_type_ID){
                                temp_construction_product_print = temp_construction_product_print + element_film.optimum_film_type_name + element_film.optimum_film_type_density + '(루프), ';
                              }
                            }
                          )
                        }
                        setConstructionProductPrint(temp_construction_product_print);
                        setTimeout(() => {
                          handlePrint();
                        }, 2000);
                      }}
                    >출력하기
                    </H.printTopBarSearchBtn>
                  </H.validatePrintBtnBox>
                  </H.validatePrintTopBarSearchResult>         
                )}
            </div>
          </H.validatePrintTopBarWrap>

          <H.validatePrintWrap>
            <p className="PrintWrapTit">|&nbsp;&nbsp; 정품 확인서 보기 &nbsp;&nbsp;|</p>
            <div className="validatePrintWrapInner">
              {guaranteeVehicleDivision === 1 ?
              <div ref={printDivRef} className="validatePrintBox">
                <p className="guatanteeImg">
                  <img className="guatanteeImgPC" src={certificate_of_authenticity} alt="PC용 정품확인서"/>
                  <img className="guatanteeImgMO" src={certificate_of_authenticity_mobile} alt="모바일용 정품확인서"/>
                </p>
                <p className="validateP_review">
                  {guaranteeReviewStatus === true ? 
                  <img src={certificate_stamp} alt="정품인증도장"/> 
                  : 
                  ''}
                </p>
                <p className="validateP_customerName">{guaranteeCustomerNamePrint}</p>{/* 고객명 */}
                <p className="validateP_carNum">{guaranteeCarNumberPrint}</p>{/* 차량번호 */}
                <p className="validateP_pointName">{constructionPointNamePrint}</p>{/* 시공점명 */}
                <p className="validateP_product">{constructionProductPrint}</p>{/* 시공제품 */}

              </div>
              :
              <div ref={printDivRef} className="validatePrintBox">
                <p className="guatanteeImg">
                  <img className="guatanteeImgPC" src={certificate_of_authenticity_construction_new} alt="PC용 정품확인서(건축용)"/>
                  <img className="guatanteeImgMO" src={certificate_of_authenticity_construction_mobile_new} alt="모바일용 정품확인서(건축용)"/>
                </p>
                <p className="validateP_review">
                  {guaranteeReviewStatus === true ? 
                  <img src={certificate_stamp} alt="정품인증도장"/> 
                  : 
                  ''}
                </p>
                <p className="validateP_customerName">{guaranteeCustomerNamePrint}</p>{/* 고객명 */}
                <p className="validateP_carNum">{constructionPointNamePrint}</p>{/* 차량번호 */}
                <p className="validateP_pointName">{guiaranteeConstructionLocation}</p>{/* 시공점명 */}
                <p className="validateP_product">{guiaranteeConstructionFilm}</p>{/* 시공제품 */}
              </div>
              }
            </div>
          </H.validatePrintWrap>
        </div>
      </H.main_validateWrap>



      
      <Footer/>
    </>
  );
}

//export default에 대한 내용은 src/Admin/Section/AdminHeader.js에 설명해났습니다.
export default Validate;
