//import에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 자세하게 설명해났습니다. 참고하세요.
import React, {useState, useEffect} from 'react';
//AdminHeader에 대한 설명은 src/Admin/Main.js 참고
import AdminHeader from '../Section/AdminHeader';
import {
  get_guarantee_one,
  update_guarantee,
  get_session_data_homepage,
  get_optimum_film_type_status_1,
  get_guarantee_period_status_1,
} from "../../Axios";
import * as H from '../../Styles/StyleComponentHan';
import {
  removeWhitespace,
  removeWhitespacePhone,
  validatePhone,
} from '../../Validation';

export function GuaranteeManagementDetail() {  
  //파라미터 데이터
  //파라미터 데이터는 Guarantee 여기에서
  //체크박스 누르고 수정을 누르면
  //http://localhost:3000/GuaranteeManagementDetail?guarantee_ID=2
  //이런식으로 주소를 이동하는데 여기서 아래와 같이 쓰면
  //guarantee_ID=2를 쓸 수 있다.
  //즉 guarantee_ID의 고유값인 2를 쓸 수 있는 것이다.
  const params = new URLSearchParams(window.location.search);
  const guarantee_ID_param = params.get("guarantee_ID");

  //useState에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고  
  //입력 데이터
  const [constructionPointIDUseGuarantee, setConstructionPointIDUseGuarantee] = useState('');
  const [guaranteeVehicleDivision, setGuaranteeVehicleDivision] = useState('1');
  const [guaranteeSerialNumber, setGuaranteeSerialNumber] = useState('');
  const [guaranteeWindshieldUseOptimumFilmTypeID, setGuaranteeWindshieldUseOptimumFilmTypeID] = useState('0');
  const [guaranteeWindshieldLotNumber, setGuaranteeWindshieldLotNumber] = useState('');
  const [guaranteeWindshieldUseGuaranteePeriodID, setGuaranteeWindshieldUseGuaranteePeriodID] = useState('0');
  const [guaranteeSideGlass1UseOptimumFilmTypeID, setGuaranteeSideGlass1UseOptimumFilmTypeID] = useState('0');
  const [guaranteeSideGlass1LotNumber, setGuaranteeSideGlass1LotNumber] = useState('');
  const [guaranteeSideGlass1UseGuaranteePeriodID, setGuaranteeSideGlass1UseGuaranteePeriodID] = useState('0');
  const [guaranteeSideGlass2UseOptimumFilmTypeID, setGuaranteeSideGlass2UseOptimumFilmTypeID] = useState('0');
  const [guaranteeSideGlass2LotNumber, setGuaranteeSideGlass2LotNumber] = useState('');
  const [guaranteeSideGlass2UseGuaranteePeriodID, setGuaranteeSideGlass2UseGuaranteePeriodID] = useState('0');
  const [guaranteeSideGlass3UseOptimumFilmTypeID, setGuaranteeSideGlass3UseOptimumFilmTypeID] = useState('0');
  const [guaranteeSideGlass3LotNumber, setGuaranteeSideGlass3LotNumber] = useState('');
  const [guaranteeSideGlass3UseGuaranteePeriodID, setGuaranteeSideGlass3UseGuaranteePeriodID] = useState('0');
  const [guaranteeRearGlassUseOptimumFilmTypeID, setGuaranteeRearGlassUseOptimumFilmTypeID] = useState('0');
  const [guaranteeRearGlassLotNumber, setGuaranteeRearGlassLotNumber] = useState('');
  const [guaranteeRearGlassUseGuaranteePeriodID, setGuaranteeRearGlassUseGuaranteePeriodID] = useState('0');
  const [guaranteeRoofGlassUseOptimumFilmTypeID, setGuaranteeRoofGlassUseOptimumFilmTypeID] = useState('0');
  const [guaranteeRoofGlassLotNumber, setGuaranteeRoofGlassLotNumber] = useState('');
  const [guaranteeRoofGlassUseGuaranteePeriodID, setGuaranteeRoofGlassUseGuaranteePeriodID] = useState('0');
  const [guaranteeConstructionDate, setGuaranteeConstructionDate] = useState('');
  const [guaranteeCarType, setGuaranteeCarType] = useState('');
  const [guaranteeCarNumber, setGuaranteeCarNumber] = useState(''); 
  const [guaranteeCustomerName, setGuaranteeCustomerName] = useState('');
  const [guaranteeCustomerPhoneNumber, setGuaranteeCustomerPhoneNumber] = useState('');
  const [guaranteeMemo, setGuaranteeMemo] = useState('');
  const [guaranteeReview, setGuaranteeReview] = useState('');
  const [guiaranteeConstructionLocation, setGuiaranteeConstructionLocation] = useState(''); 
  const [guiaranteeConstructionFilm, setGuiaranteeConstructionFilm] = useState(''); 

  //셀렉트 데이터
  const [optimumFilmTypeSelect, setOptimumFilmTypeSelect] = useState([]);
  const [guaranteePeriodSelect, setGuaranteePeriodSelect] = useState([]);

  //에러 출력 데이터
  const [guaranteeWindshieldUseOptimumFilmTypeIDError, setGuaranteeWindshieldUseOptimumFilmTypeIDError] = useState('');
  const [guaranteeSideGlass1UseOptimumFilmTypeIDrror, setGuaranteeSideGlass1UseOptimumFilmTypeIDError] = useState('');
  const [guaranteeSideGlass2UseOptimumFilmTypeIDrror, setGuaranteeSideGlass2UseOptimumFilmTypeIDError] = useState('');
  const [guaranteeSideGlass3UseOptimumFilmTypeIDrror, setGuaranteeSideGlass3UseOptimumFilmTypeIDError] = useState('');
  const [guaranteeRearGlassUseOptimumFilmTypeIDrror, setGaranteeRearGlassUseOptimumFilmTypeIDError] = useState('');
  const [guaranteeRoofGlassUseOptimumFilmTypeIDrror, setGaranteeRoofGlassUseOptimumFilmTypeIDError] = useState('');
  const [guaranteeConstructionDateError, setGuaranteeConstructionDateError] = useState('');
  const [guaranteeCarTypeError, setGuaranteeCarTypeError] = useState('');
  const [guaranteeCarNumberError, setGuaranteeCarNumberError] = useState('');
  const [guaranteeCustomerNameError, setGuaranteeCustomerNameError] = useState('');
  const [guaranteeCustomerPhoneNumberError, setGuaranteeCustomerPhoneNumberError] = useState('');
  const [guiaranteeConstructionLocationError, setGuiaranteeConstructionLocationError] = useState(''); 
  const [guiaranteeConstructionFilmError, setGuiaranteeConstructionFilmError] = useState(''); 

  //휴대폰 형식 체킹
  const validHcCustomerPhoneNumber = (value)=>{
    setGuaranteeCustomerPhoneNumber(value);
    if(!validatePhone(value)){
      setGuaranteeCustomerPhoneNumberError("휴대폰번호 형식으로 입력해주세요.");
    }else{
      setGuaranteeCustomerPhoneNumberError('');
    }
  }

  //컨트롤 함수
  const form_check = () =>{
    let continue_add = true;
    //여기는 등록버튼을 눌렀을 시 값을 안넣으면 에러데이터를 뿌려주는 곳입니다.
    //그리고 에러가 발생되면 위에 continue_add를 false로 바꿔서
  if(guaranteeVehicleDivision === '1'){
    if(
      guaranteeWindshieldUseOptimumFilmTypeID !== '0' && (
      guaranteeWindshieldLotNumber === '' ||
      guaranteeWindshieldUseGuaranteePeriodID === '0')
    ){setGuaranteeWindshieldUseOptimumFilmTypeIDError('전면유리 사항을 다 입력해주세요.'); continue_add = false;}else{setGuaranteeWindshieldUseOptimumFilmTypeIDError('');}
    if(
      guaranteeSideGlass1UseOptimumFilmTypeID !== '0' && (
      guaranteeSideGlass1LotNumber === '' ||
      guaranteeSideGlass1UseGuaranteePeriodID === '0')
    ){setGuaranteeSideGlass1UseOptimumFilmTypeIDError('측면유리1 사항을 다 입력해주세요.'); continue_add = false;}else{setGuaranteeSideGlass1UseOptimumFilmTypeIDError('');}
    if(
      guaranteeSideGlass2UseOptimumFilmTypeID !== '0' && (
      guaranteeSideGlass2LotNumber === '' ||
      guaranteeSideGlass2UseGuaranteePeriodID === '0')
    ){setGuaranteeSideGlass2UseOptimumFilmTypeIDError('측면유리2 사항을 다 입력해주세요.'); continue_add = false;}else{setGuaranteeSideGlass2UseOptimumFilmTypeIDError('');}
    if(
      guaranteeSideGlass3UseOptimumFilmTypeID !== '0' && (
      guaranteeSideGlass3LotNumber === '' ||
      guaranteeSideGlass3UseGuaranteePeriodID === '0')
    ){setGuaranteeSideGlass3UseOptimumFilmTypeIDError('측면유리3 사항을 다 입력해주세요.'); continue_add = false;}else{setGuaranteeSideGlass3UseOptimumFilmTypeIDError('');}
    if(
      guaranteeRearGlassUseOptimumFilmTypeID !== '0' && (
      guaranteeRearGlassLotNumber === '' ||
      guaranteeRearGlassUseGuaranteePeriodID === '0')
    ){setGaranteeRearGlassUseOptimumFilmTypeIDError('후면유리 사항을 다 입력해주세요.'); continue_add = false;}else{setGaranteeRearGlassUseOptimumFilmTypeIDError('');}
    if(
      guaranteeRoofGlassUseOptimumFilmTypeID !== '0' && (
      guaranteeRoofGlassLotNumber === '' ||
      guaranteeRoofGlassUseGuaranteePeriodID === '0')
    ){setGaranteeRoofGlassUseOptimumFilmTypeIDError('루프유리 사항을 다 입력해주세요.'); continue_add = false;}else{setGaranteeRoofGlassUseOptimumFilmTypeIDError('');}
    if(guaranteeCarType === ''){setGuaranteeCarTypeError('차종을 입력해주세요.'); continue_add = false;}else{setGuaranteeCarTypeError('');}
    if(guaranteeCarNumber === ''){setGuaranteeCarNumberError('차량번호를 입력해주세요.'); continue_add = false;}else{setGuaranteeCarNumberError('');}
  }else if(guaranteeVehicleDivision === '2' || guaranteeVehicleDivision === '3'){
      if(guiaranteeConstructionLocation === ''){setGuiaranteeConstructionLocationError('시공위치를 입력해주세요.'); continue_add = false;}else{setGuiaranteeConstructionLocationError('');}
      if(guiaranteeConstructionFilm === ''){setGuiaranteeConstructionFilmError('시공필름을 입력해주세요.'); continue_add = false;}else{setGuiaranteeConstructionFilmError('');}
    }

    if(guaranteeConstructionDate === ''){setGuaranteeConstructionDateError('시공일자를 입력해주세요.'); continue_add = false;}else{setGuaranteeConstructionDateError('');}
    if(guaranteeCustomerName === ''){setGuaranteeCustomerNameError('고객명을 입력해주세요.'); continue_add = false;}else{setGuaranteeCustomerNameError('');}
    if(!validatePhone(guaranteeCustomerPhoneNumber)){setGuaranteeCustomerPhoneNumberError("휴대폰번호 형식으로 입력해주세요."); continue_add = false;}else{setGuaranteeCustomerPhoneNumberError('');}
    if(guaranteeCustomerPhoneNumber === ''){setGuaranteeCustomerPhoneNumberError('고객전화번호를 입력해주세요.'); continue_add = false;}else{setGuaranteeCustomerPhoneNumberError('');}

    //아래 false일 경우에는 등록하지 않습니다.
    if(continue_add){
      //파라미터가 없으면 업데이트 한다.
      update_guarantee(
        guarantee_ID_param,
        constructionPointIDUseGuarantee,
        guaranteeVehicleDivision,
        guaranteeWindshieldUseOptimumFilmTypeID,
        guaranteeWindshieldLotNumber,
        guaranteeWindshieldUseGuaranteePeriodID,
        guaranteeSideGlass1UseOptimumFilmTypeID,
        guaranteeSideGlass1LotNumber,
        guaranteeSideGlass1UseGuaranteePeriodID,
        guaranteeSideGlass2UseOptimumFilmTypeID,
        guaranteeSideGlass2LotNumber,
        guaranteeSideGlass2UseGuaranteePeriodID,
        guaranteeSideGlass3UseOptimumFilmTypeID,
        guaranteeSideGlass3LotNumber,
        guaranteeSideGlass3UseGuaranteePeriodID,
        guaranteeRearGlassUseOptimumFilmTypeID,
        guaranteeRearGlassLotNumber,
        guaranteeRearGlassUseGuaranteePeriodID,
        guaranteeRoofGlassUseOptimumFilmTypeID,
        guaranteeRoofGlassLotNumber,
        guaranteeRoofGlassUseGuaranteePeriodID,
        guaranteeConstructionDate,
        guaranteeCarType,
        guaranteeCarNumber,
        guaranteeCustomerName,
        guaranteeCustomerPhoneNumber,
        guaranteeReview,
        guaranteeMemo,
        guiaranteeConstructionLocation,
        guiaranteeConstructionFilm,
      ).then(()=>{
        alert('수정이 완료되었습니다.');
        window.location.href = "/GuaranteeManagement";
      });
    }else{
      alert('필수 입력값을 확인해주세요.');
    }
  }

  // useEffect는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Section/AdminHeader.js파일에 설명을 해났음
  useEffect(() => {
    //백엔드 서버에 세션이 있는지 없는지 여부에 따라 로그인을 해야되는지 말아야되는지 판단하는 함수
    get_session_data_homepage().then((result)=>{
      //세션에 저장되어있는 보증서 ID를 저장
      setConstructionPointIDUseGuarantee(result.construction_point_ID);

      //옵티멈필름 종류 중 사용가능 한 데이터만 가져오는 API 자세한 내용은 src/Axios.js 파일 확인
      get_optimum_film_type_status_1().then((result)=>{
        setOptimumFilmTypeSelect(result);
      });

      //보증기간을 사용가능 한 데이터만 가져오는 API 자세한 내용은 src/Axios.js 파일 확인
      get_guarantee_period_status_1().then((result)=>{
        setGuaranteePeriodSelect(result);
      });
      
      //만약에 파라미터를 보내지 않으면 guarantee_ID값은 null(빈값으로 오기 떄문에)
      //아래 if조건문에서 null이 아니라면 데이터를 가져올 것이다.
      if(guarantee_ID_param !== null){
        //특정 ID의 보증서 데이터를 가져오기 위한 함수
        get_guarantee_one(guarantee_ID_param).then((result) => {
          //데이터를 가져오면 데이터를 집어넣어준다.
          setGuaranteeVehicleDivision(String(result[0].guarantee_vehicle_division));
          setGuaranteeSerialNumber(result[0].guarantee_serial_number);
          setGuaranteeWindshieldUseOptimumFilmTypeID(`${result[0].guarantee_windshield_use_optimum_film_type_ID}`);
          setGuaranteeWindshieldUseGuaranteePeriodID(`${result[0].guarantee_windshield_use_guarantee_period_ID}`);
          setGuaranteeWindshieldLotNumber(result[0].guarantee_windshield_lot_number);
          setGuaranteeSideGlass1UseOptimumFilmTypeID(`${result[0].guarantee_side_glass_1_use_optimum_film_type_ID}`);
          setGuaranteeSideGlass1UseGuaranteePeriodID(`${result[0].guarantee_side_glass_1_use_guarantee_period_ID}`);
          setGuaranteeSideGlass1LotNumber(result[0].guarantee_side_glass_1_lot_number);
          setGuaranteeSideGlass2UseOptimumFilmTypeID(`${result[0].guarantee_side_glass_2_use_optimum_film_type_ID}`);
          setGuaranteeSideGlass2UseGuaranteePeriodID(`${result[0].guarantee_side_glass_2_use_guarantee_period_ID}`);
          setGuaranteeSideGlass2LotNumber(result[0].guarantee_side_glass_2_lot_number);
          setGuaranteeSideGlass3UseOptimumFilmTypeID(`${result[0].guarantee_side_glass_3_use_optimum_film_type_ID}`);
          setGuaranteeSideGlass3UseGuaranteePeriodID(`${result[0].guarantee_side_glass_3_use_guarantee_period_ID}`);
          setGuaranteeSideGlass3LotNumber(result[0].guarantee_side_glass_3_lot_number);
          setGuaranteeRearGlassUseOptimumFilmTypeID(`${result[0].guarantee_rear_glass_use_optimum_film_type_ID}`);
          setGuaranteeRearGlassUseGuaranteePeriodID(`${result[0].guarantee_rear_glass_use_guarantee_period_ID}`);
          setGuaranteeRearGlassLotNumber(result[0].guarantee_rear_glass_lot_number);
          setGuaranteeRoofGlassUseOptimumFilmTypeID(`${result[0].guarantee_roof_glass_use_optimum_film_type_ID}`);
          setGuaranteeRoofGlassUseGuaranteePeriodID(`${result[0].guarantee_roof_glass_use_guarantee_period_ID}`);
          setGuaranteeRoofGlassLotNumber(result[0].guarantee_roof_glass_lot_number);
          setGuaranteeConstructionDate(result[0].guarantee_construction_date);
          setGuaranteeCarType(result[0].guarantee_car_type);
          setGuaranteeCarNumber(result[0].guarantee_car_number);
          setGuaranteeCustomerName(result[0].guarantee_customer_name);
          setGuaranteeCustomerPhoneNumber(result[0].guarantee_customer_phone_number);
          setGuaranteeReview(result[0].guarantee_review);
          setGuaranteeMemo(result[0].guarantee_memo);
          setGuiaranteeConstructionLocation(result[0].guiarantee_construction_location);
          setGuiaranteeConstructionFilm(result[0].guiarantee_construction_film);
        })
      }
    })
  },[guarantee_ID_param]);

  return (
    <> 
      <H.admin_MainWrap> 
        <AdminHeader/>
        <H.admin_rightWrap>
          <H.admin_rightTitBox>
            <H.admin_rightTitInner>
              <p>보증서 수정 및 상세보기</p>
            </H.admin_rightTitInner>
          </H.admin_rightTitBox>
          <H.admin_rightInfoBox>
            <H.admin_rightInfoInner>
              <H.admin_labelBox>
                {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
                <label htmlFor='guarantee_serial_number'>Serial No.</label>
                <input 
                  type='text' 
                  id = 'guarantee_serial_number' 
                  placeholder = "등록 시 자동으로 생성됩니다."
                  value={guaranteeSerialNumber}
                  readOnly
                />
              </H.admin_labelBox>

              <H.admin_labelBox>
                {/* 여기는 수정에서만 보임 */
                /* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
                <label htmlFor='guarantee_vehicle_division'>보증서 구분<span> * </span> </label>
                <select
                  id='guarantee_vehicle_division'
                  value={guaranteeVehicleDivision}
                  //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                  onChange={(event_data)=>{
                    setGuaranteeVehicleDivision(event_data.target.value);
                  }}
                >
                  <option value='1'>차량용</option>
                  <option value='3'>PPF</option>
                  <option value='2'>건축용</option>
                </select>
              </H.admin_labelBox>

              {guaranteeVehicleDivision === '1' && 
              <>
              <H.admin_guarantee_labelBox>
                {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
                <div>
                  <label htmlFor='guarantee_windshield_use_optimum_film_type_ID'>전면유리</label>
                  <select
                    id='guarantee_windshield_use_optimum_film_type_ID'
                    value={guaranteeWindshieldUseOptimumFilmTypeID}
                    //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                    onChange={(event_data)=>{
                      setGuaranteeWindshieldUseOptimumFilmTypeID(event_data.target.value);
                      if(event_data.target.value === '0'){
                        setGuaranteeWindshieldLotNumber('');
                        setGuaranteeWindshieldUseGuaranteePeriodID('0');
                      }
                    }}
                    >
                    <option value='0'>필름을 선택해 주세요</option>
                    {optimumFilmTypeSelect.map((element, index) => 
                      <option value={element.optimum_film_type_ID} key={index}>
                        {element.optimum_film_type_name} {element.optimum_film_type_density}
                      </option>
                    )}
                  </select>
                </div>

                {guaranteeWindshieldUseOptimumFilmTypeID !== '0' && 
                  <>
                  <div>
                    <p>Lot 번호</p>
                    <input 
                      type='text' 
                      id = 'guarantee_windshield_lot_number' 
                      maxLength={100} 
                      //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                      onChange={(event_data)=>{
                        setGuaranteeWindshieldLotNumber(event_data.target.value);
                      }} 
                      placeholder = "Lot번호를 입력해 주세요."
                      value={guaranteeWindshieldLotNumber}
                    />
                  </div>

                  <div>
                    <p>보증기간</p>
                    <select
                      id='guarantee_windshield_use_guarantee_period_ID'
                      value={guaranteeWindshieldUseGuaranteePeriodID}
                      //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                      onChange={(event_data)=>{
                        setGuaranteeWindshieldUseGuaranteePeriodID(event_data.target.value);
                      }}
                      >
                      <option value='0'>보증기간을 선택해 주세요</option>
                      {guaranteePeriodSelect.map((element, index) => 
                        <option value={element.guarantee_period_ID} key={index}>
                          {element.guarantee_period_year}
                        </option>
                      )}
                    </select>
                  </div>
                  </>
                  }
                <H.admin_errorBox className="marginLeft150">{guaranteeWindshieldUseOptimumFilmTypeIDError}</H.admin_errorBox>
              </H.admin_guarantee_labelBox>

              <H.admin_guarantee_labelBox>
                {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
                <div>
                  <label htmlFor='guarantee_side_glass_1_use_optimum_film_type_ID'>측면유리1</label>
                  <select
                    id='guarantee_side_glass_1_use_optimum_film_type_ID'
                    value={guaranteeSideGlass1UseOptimumFilmTypeID}
                    //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                    onChange={(event_data)=>{
                      setGuaranteeSideGlass1UseOptimumFilmTypeID(event_data.target.value);
                      if(event_data.target.value === '0'){
                        setGuaranteeSideGlass1LotNumber('');
                        setGuaranteeSideGlass1UseGuaranteePeriodID('0');
                      }
                    }}
                  >
                    <option value='0'>필름선택</option>
                    {optimumFilmTypeSelect.map((element, index) => 
                      <option value={element.optimum_film_type_ID} key={index}>
                        {element.optimum_film_type_name} {element.optimum_film_type_density}
                      </option>
                    )}
                  </select>
                </div>

                {guaranteeSideGlass1UseOptimumFilmTypeID !== '0' && 
                  <>
                  <div>
                    <p>Lot 번호</p>
                    <input 
                      type='text' 
                      id = 'guarantee_side_glass_1_lot_number' 
                      maxLength={100} 
                      //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                      onChange={(event_data)=>{
                        setGuaranteeSideGlass1LotNumber(event_data.target.value);
                      }} 
                      placeholder = "Lot번호를 입력해 주세요."
                      value={guaranteeSideGlass1LotNumber}
                    />
                  </div>

                  <div>
                    <p>보증기간</p>
                    <select
                      id='guarantee_side_glass_1_use_guarantee_period_ID'
                      value={guaranteeSideGlass1UseGuaranteePeriodID}
                      //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                      onChange={(event_data)=>{
                        setGuaranteeSideGlass1UseGuaranteePeriodID(event_data.target.value);
                      }}
                      >
                      <option value='0'>보증기간선택</option>
                      {guaranteePeriodSelect.map((element, index) => 
                        <option value={element.guarantee_period_ID} key={index}>
                          {element.guarantee_period_year}
                        </option>
                      )}
                    </select>
                  </div>
                  </>
                }
                <H.admin_errorBox className="marginLeft150">{guaranteeSideGlass1UseOptimumFilmTypeIDrror}</H.admin_errorBox>
              </H.admin_guarantee_labelBox>

              <H.admin_guarantee_labelBox>
                {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
                <div>
                  <label htmlFor='guarantee_side_glass_2_use_optimum_film_type_ID'>측면유리2</label>
                  <select
                    id='guarantee_side_glass_2_use_optimum_film_type_ID'
                    value={guaranteeSideGlass2UseOptimumFilmTypeID}
                    //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                    onChange={(event_data)=>{
                      setGuaranteeSideGlass2UseOptimumFilmTypeID(event_data.target.value);
                      if(event_data.target.value === '0'){
                        setGuaranteeSideGlass2LotNumber('');
                        setGuaranteeSideGlass2UseGuaranteePeriodID('0');
                      }
                    }}
                  >
                    <option value='0'>필름선택</option>
                    {optimumFilmTypeSelect.map((element, index) => 
                      <option value={element.optimum_film_type_ID} key={index}>
                        {element.optimum_film_type_name} {element.optimum_film_type_density}
                      </option>
                    )}
                  </select>
                </div>
                {guaranteeSideGlass2UseOptimumFilmTypeID !== '0' && 
                  <>
                  <div>
                    <p>Lot 번호</p>
                    <input 
                      type='text' 
                      id = 'guarantee_side_glass_2_lot_number' 
                      maxLength={100} 
                      //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                      onChange={(event_data)=>{
                        setGuaranteeSideGlass2LotNumber(event_data.target.value);
                      }} 
                      placeholder = "Lot번호를 입력해 주세요."
                      value={guaranteeSideGlass2LotNumber}
                    />
                  </div>
                  <div>
                    <p>보증기간</p>
                    <select
                      id='guarantee_side_glass_2_use_guarantee_period_ID'
                      value={guaranteeSideGlass2UseGuaranteePeriodID}
                      //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                      onChange={(event_data)=>{
                        setGuaranteeSideGlass2UseGuaranteePeriodID(event_data.target.value);
                      }}
                    >
                      <option value='0'>보증기간선택</option>
                      {guaranteePeriodSelect.map((element, index) => 
                        <option value={element.guarantee_period_ID} key={index}>
                          {element.guarantee_period_year}
                        </option>
                      )}
                    </select>
                  </div>
                  </>
                }
                <H.admin_errorBox className="marginLeft150">{guaranteeSideGlass2UseOptimumFilmTypeIDrror}</H.admin_errorBox>
              </H.admin_guarantee_labelBox>

              <H.admin_guarantee_labelBox>
                {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
                <div>
                  <label htmlFor='guarantee_side_glass_3_use_optimum_film_type_ID'>측면유리3</label>
                  <select
                    id='guarantee_side_glass_3_use_optimum_film_type_ID'
                    value={guaranteeSideGlass3UseOptimumFilmTypeID}
                    //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                    onChange={(event_data)=>{
                      setGuaranteeSideGlass3UseOptimumFilmTypeID(event_data.target.value);
                      if(event_data.target.value === '0'){
                        setGuaranteeSideGlass3LotNumber('');
                        setGuaranteeSideGlass3UseGuaranteePeriodID('0');
                      }
                    }}
                  >
                    <option value='0'>필름선택</option>
                    {optimumFilmTypeSelect.map((element, index) => 
                      <option value={element.optimum_film_type_ID} key={index}>
                        {element.optimum_film_type_name} {element.optimum_film_type_density}
                      </option>
                    )}
                  </select>
                </div>
                {guaranteeSideGlass3UseOptimumFilmTypeID !== '0' && 
                  <>
                  <div>
                    <p>Lot 번호</p>
                    <input 
                      type='text' 
                      id = 'guarantee_side_glass_3_lot_number' 
                      maxLength={100} 
                      //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                      onChange={(event_data)=>{
                        setGuaranteeSideGlass3LotNumber(event_data.target.value);
                      }} 
                      placeholder = "Lot번호를 입력해 주세요."
                      value={guaranteeSideGlass3LotNumber}
                    />
                  </div>
                  <div>
                    <p>보증기간</p>
                    <select
                      id='guarantee_side_glass_3_use_guarantee_period_ID'
                      value={guaranteeSideGlass3UseGuaranteePeriodID}
                      //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                      onChange={(event_data)=>{
                        setGuaranteeSideGlass3UseGuaranteePeriodID(event_data.target.value);
                      }}
                    >
                      <option value='0'>보증기간선택</option>
                      {guaranteePeriodSelect.map((element, index) => 
                        <option value={element.guarantee_period_ID} key={index}>
                          {element.guarantee_period_year}
                        </option>
                      )}
                    </select>
                  </div>
                  </>
                }
                <H.admin_errorBox className="marginLeft150">{guaranteeSideGlass3UseOptimumFilmTypeIDrror}</H.admin_errorBox>
              </H.admin_guarantee_labelBox>

              <H.admin_guarantee_labelBox>
                {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
                <div>
                  <label htmlFor='guarantee_rear_glass_use_optimum_film_type_ID'>후면유리</label>
                  <select
                    id='guarantee_rear_glass_use_optimum_film_type_ID'
                    value={guaranteeRearGlassUseOptimumFilmTypeID}
                    //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                    onChange={(event_data)=>{
                      setGuaranteeRearGlassUseOptimumFilmTypeID(event_data.target.value);
                      if(event_data.target.value === '0'){
                        setGuaranteeRearGlassLotNumber('');
                        setGuaranteeRearGlassUseGuaranteePeriodID('0');
                      }
                    }}
                  >
                    <option value='0'>필름선택</option>
                    {optimumFilmTypeSelect.map((element, index) => 
                      <option value={element.optimum_film_type_ID} key={index}>
                        {element.optimum_film_type_name} {element.optimum_film_type_density}
                      </option>
                    )}
                  </select>
                </div>
                {guaranteeRearGlassUseOptimumFilmTypeID !== '0' && 
                  <>
                  <div>
                    <p>Lot 번호</p>
                    <input 
                      type='text' 
                      id = 'guarantee_rear_glass_lot_number' 
                      maxLength={100} 
                      //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                      onChange={(event_data)=>{
                        setGuaranteeRearGlassLotNumber(event_data.target.value);
                      }} 
                      placeholder = "Lot번호를 입력해 주세요."
                      value={guaranteeRearGlassLotNumber}
                    />
                  </div>
                  <div>
                    <p>보증기간</p>
                    <select
                      id='guarantee_rear_glass_use_guarantee_period_ID'
                      value={guaranteeRearGlassUseGuaranteePeriodID}
                      //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                      onChange={(event_data)=>{
                        setGuaranteeRearGlassUseGuaranteePeriodID(event_data.target.value);
                      }}
                    >
                      <option value='0'>보증기간선택</option>
                      {guaranteePeriodSelect.map((element, index) => 
                        <option value={element.guarantee_period_ID} key={index}>
                          {element.guarantee_period_year}
                        </option>
                      )}
                    </select>
                  </div>
                  </>
                }
                <H.admin_errorBox className="marginLeft150">{guaranteeRearGlassUseOptimumFilmTypeIDrror}</H.admin_errorBox>
              </H.admin_guarantee_labelBox>

              <H.admin_guarantee_labelBox>
                {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
                <div>
                  <label htmlFor='guarantee_roof_glass_use_optimum_film_type_ID'>루프유리</label>
                  <select
                    id='guarantee_roof_glass_use_optimum_film_type_ID'
                    value={guaranteeRoofGlassUseOptimumFilmTypeID}
                    //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                    onChange={(event_data)=>{
                      setGuaranteeRoofGlassUseOptimumFilmTypeID(event_data.target.value);
                      if(event_data.target.value === '0'){
                        setGuaranteeRoofGlassLotNumber('');
                        setGuaranteeRoofGlassUseGuaranteePeriodID('0');
                      }
                    }}
                  >
                    <option value='0'>필름선택</option>
                    {optimumFilmTypeSelect.map((element, index) => 
                      <option value={element.optimum_film_type_ID} key={index}>
                        {element.optimum_film_type_name} {element.optimum_film_type_density}
                      </option>
                    )}
                  </select>
                </div>
                {guaranteeRoofGlassUseOptimumFilmTypeID !== '0' && 
                  <>
                  <div>
                    <p>Lot 번호</p>
                    <input 
                      type='text' 
                      id = 'guarantee_roof_glass_lot_number' 
                      maxLength={100} 
                      //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                      onChange={(event_data)=>{
                        setGuaranteeRoofGlassLotNumber(event_data.target.value);
                      }} 
                      placeholder = "Lot번호를 입력해 주세요."
                      value={guaranteeRoofGlassLotNumber}
                    />
                  </div>
                  <div>
                    <p>보증기간</p>
                    <select
                      id='guarantee_roof_glass_use_guarantee_period_ID'
                      value={guaranteeRoofGlassUseGuaranteePeriodID}
                      //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                      onChange={(event_data)=>{
                        setGuaranteeRoofGlassUseGuaranteePeriodID(event_data.target.value);
                      }}
                    >
                      <option value='0'>보증기간선택</option>
                      {guaranteePeriodSelect.map((element, index) => 
                        <option value={element.guarantee_period_ID} key={index}>
                          {element.guarantee_period_year}
                        </option>
                      )}
                    </select>
                  </div>
                  </>
                }
                <H.admin_errorBox className="marginLeft150">{guaranteeRoofGlassUseOptimumFilmTypeIDrror}</H.admin_errorBox>
              </H.admin_guarantee_labelBox>
              </>
              }
              <H.admin_labelBox>
                {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
                <label htmlFor='guarantee_construction_date'>시공일자<span> * </span></label>
                
                <input
                  className="inputDate"
                  type='date' 
                  id = 'guarantee_construction_date' 
                  max = '2999-01-01'
                  //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                  onChange={(event_data)=>{
                    setGuaranteeConstructionDate(event_data.target.value);
                  }} 
                  value={guaranteeConstructionDate}
                />
                
                <H.admin_errorBox className="marginLeft150">{guaranteeConstructionDateError}</H.admin_errorBox>
              </H.admin_labelBox>
              {guaranteeVehicleDivision === '1' && 
              <>
              <H.admin_labelBox>
                {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
                <label htmlFor='guarantee_car_type'>차종<span> * </span></label>
                <input 
                  type='text' 
                  id = 'guarantee_car_type' 
                  maxLength={50} 
                  //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                  onChange={(event_data)=>{
                    setGuaranteeCarType(event_data.target.value);
                  }} 
                  placeholder = "차종을 입력해주세요."
                  value={guaranteeCarType}
                />
                <H.admin_errorBox className="marginLeft150">{guaranteeCarTypeError}</H.admin_errorBox>
              </H.admin_labelBox>

              <H.admin_labelBox>
                {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
                <label htmlFor='guarantee_car_number'>차량번호<span> * </span></label>
                <input 
                  type='text' 
                  id = 'guarantee_car_number' 
                  maxLength={50} 
                  //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                  onChange={(event_data)=>{
                    setGuaranteeCarNumber(removeWhitespace(event_data.target.value));
                  }} 
                  placeholder = "차량번호를 입력해 주세요."
                  value={guaranteeCarNumber}
                />
                <H.admin_errorBox className="marginLeft150">{guaranteeCarNumberError}</H.admin_errorBox>
              </H.admin_labelBox>
              </>
              }
              {(guaranteeVehicleDivision === '2' || guaranteeVehicleDivision === '3') && 
              <>
              <H.admin_labelBox>
                {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
                <label htmlFor='guiarantee_construction_location'>시공위치<span> * </span></label>
                <input 
                  type='text' 
                  id = 'guiarantee_construction_location' 
                  maxLength={20} 
                  //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                  onChange={(event_data)=>{
                    setGuiaranteeConstructionLocation(event_data.target.value);
                  }} 
                  placeholder = "시공위치를 입력해 주세요."
                  value={guiaranteeConstructionLocation}
                />
                <H.admin_errorBox className="marginLeft150">{guiaranteeConstructionLocationError}</H.admin_errorBox>
              </H.admin_labelBox>

              <H.admin_labelBox>
                {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
                <label htmlFor='guiarantee_construction_film'>시공필름<span> * </span></label>
                <input 
                  type='text' 
                  id = 'guiarantee_construction_film' 
                  maxLength={20} 
                  //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                  onChange={(event_data)=>{
                    setGuiaranteeConstructionFilm(event_data.target.value);
                  }} 
                  placeholder = "시공필름을 입력해 주세요."
                  value={guiaranteeConstructionFilm}
                />
                <H.admin_errorBox className="marginLeft150">{guiaranteeConstructionFilmError}</H.admin_errorBox>
              </H.admin_labelBox>
              </>
              }

              <H.admin_labelBox>
                {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
                <label htmlFor='guarantee_customer_name'>고객명<span> * </span></label>
                <input 
                  type='text' 
                  id = 'guarantee_customer_name' 
                  maxLength={20} 
                  //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                  onChange={(event_data)=>{
                    setGuaranteeCustomerName(event_data.target.value);
                  }} 
                  placeholder = "고객명을 입력해 주세요."
                  value={guaranteeCustomerName}
                />
                <H.admin_errorBox className="marginLeft150">{guaranteeCustomerNameError}</H.admin_errorBox>
              </H.admin_labelBox>
              <H.admin_labelBox>
                {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
                <label htmlFor='guarantee_customer_phone_number'>고객전화번호<span> * </span> </label>
                <input 
                  type='text' 
                  id = 'guarantee_customer_phone_number' 
                  maxLength={20} 
                  //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                  onChange={(event_data)=>{
                    validHcCustomerPhoneNumber(removeWhitespacePhone(event_data.target.value));
                  }} 
                  placeholder = "고객전화번호를 입력해 주세요."
                  value={guaranteeCustomerPhoneNumber}
                />
                <H.admin_errorBox className="marginLeft150">{guaranteeCustomerPhoneNumberError}</H.admin_errorBox>
              </H.admin_labelBox>

              <H.admin_labelBox>
                {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
                <label htmlFor='guarantee_review'>후기링크</label>
                <input 
                  type='text' 
                  id = 'guarantee_review' 
                  maxLength={200} 
                  //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                  onChange={(event_data)=>{
                    setGuaranteeReview(event_data.target.value);
                  }} 
                  placeholder = "후기링크를 입력해 주세요."
                  value={guaranteeReview}
                />
              </H.admin_labelBox>
              
              <H.admin_labelBox>
                {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
                <label htmlFor='guarantee_memo'>메모</label>
                <input 
                  type='text' 
                  id = 'guarantee_memo' 
                  maxLength={200} 
                  //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                  onChange={(event_data)=>{
                    setGuaranteeMemo(event_data.target.value);
                  }} 
                  placeholder = "클릭 후 기타 메모를 입력할 수 있습니다."
                  value={guaranteeMemo}
                />
              </H.admin_labelBox>

              <button
                //onClick event에 대한 내용은 src/Admin/Login.js 참고
                onClick={()=>{form_check(); }}>
                {/* 보증서 파라미터가 있는지 없는지에 따라 등록인지 수정인지 판단 */}
                {guarantee_ID_param === null ? ' 등록 ' : ' 수정 '}
              </button>

            </H.admin_rightInfoInner>
          </H.admin_rightInfoBox>
        </H.admin_rightWrap>

        
      </H.admin_MainWrap>
    </>
  );
}

//export default에 대한 내용은 src/Admin/Section/AdminHeader.js에 설명해났습니다.
export default GuaranteeManagementDetail;
