//import에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 자세하게 설명해났습니다. 참고하세요.
import React, {useState, useEffect} from 'react';
import Modal from 'react-modal'; // react-modal import
//AdminHeader에 대한 설명은 src/Admin/Main.js 참고
import AdminHeader from '../../Section/AdminHeader';
import {
  insert_construction_point,
  get_construction_point_one,
  update_construction_point,
  get_x_y_coordinate,
} from "../../../Axios";
import Autocomplete from 'react-google-autocomplete';

import * as H from '../../../Styles/StyleComponentHan';

//이건 다음에서 제공하는 주소검색기능 패키지입니다.
//npm install react-daum-postcode 으로 설치했고
//사용 방법은 아래 <DaumPostcode 참고하시면 됩니다.
import DaumPostcode from 'react-daum-postcode';


export function AdminOCPointManagementAdd() {  
  //파라미터 데이터
  //파라미터 데이터는 AdminconstructionPointManagement여기에서
  //체크박스 누르고 수정을 누르면
  //http://localhost:3000/AdminOCPointManagementAdd?construction_point_ID=2
  //이런식으로 주소를 이동하는데 여기서 아래와 같이 쓰면
  //construction_point_ID=2를 쓸 수 있다.
  //즉 construction_point_ID의 고유값인 2를 쓸 수 있는 것이다.
  const params = new URLSearchParams(window.location.search);
  const construction_point_ID_param = params.get("construction_point_ID");
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  //useState에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고  
  //입력 데이터
  const [constructionPointName, setConstructionPointName] = useState('');
  const [constructionPointRepresentative, setConstructionPointRepresentative] = useState('');
  const [constructionPointPhoneNumber, setConstructionPointPhoneNumber] = useState('');
  const [constructionPointZipCode, setConstructionPointZipCode] = useState('');
  const [constructionPointRoadAddress, setConstructionPointRoadAddress] = useState('');
  const [constructionPointDetailAddress, setConstructionPointDetailAddress] = useState('');
  const [constructionPointBigArea, setConstructionPointBigArea] = useState('');
  const [constructionPointSmallArea, setConstructionPointSmallArea] = useState('');
  const [constructionPointKakaoUrl, setConstructionPointKakaoUrl] = useState('');
  const [constructionPointStatus, setConstructionPointStatus] = useState('1');
  const [constructionPointX, setConstructionPointX] = useState('');
  const [constructionPointY, setConstructionPointY] = useState('');
  const [constructionPointDivision, setConstructionPointDivision] = useState('1');
  const [constructionPointLoginID, setConstructionPointLoginID] = useState('');
  const [constructionPointPassword, setConstructionPointPassword] = useState('');

  //에러 출력 데이터
  const [constructionPointNameError, setConstructionPointNameError] = useState('');
  const [constructionPointRepresentativeError, setConstructionPointRepresentativeError] = useState('');
  const [constructionPointPhoneNumberError, setConstructionPointPhoneNumberError] = useState('');
  const [constructionPointRoadAddressError, setConstructionPointRoadAddressError] = useState('');

  //컨트롤 데이터
  const [openPostCode, setOpenPostCode] = useState(false);
  const [country, setCountry] = useState('kr');

  //컨트롤 함수
  const form_check = () =>{
    let continue_add = true;
    //여기는 등록버튼을 눌렀을 시 값을 안넣으면 에러데이터를 뿌려주는 곳입니다.
    //그리고 에러가 발생되면 위에 continue_add를 false로 바꿔서
    if(constructionPointName === ''){setConstructionPointNameError('시공점명을 입력해주세요.'); continue_add = false;}else{setConstructionPointNameError('');}
    if(constructionPointRepresentative === ''){setConstructionPointRepresentativeError('대표자를 입력해주세요.'); continue_add = false;}else{setConstructionPointRepresentativeError('');}
    if(constructionPointPhoneNumber === ''){setConstructionPointPhoneNumberError('대표번호를 입력해주세요.'); continue_add = false;}else{setConstructionPointPhoneNumberError('');}
    if(constructionPointRoadAddress === ''){setConstructionPointRoadAddressError('주소를 입력해주세요.'); continue_add = false;}else{setConstructionPointRoadAddressError('');}

    //아래 false일 경우에는 등록하지 않습니다.
    if(continue_add){
      //파라미터가 있으면 저장하고
      if(construction_point_ID_param === null){
        insert_construction_point(
          constructionPointName,
          constructionPointRepresentative,
          constructionPointPhoneNumber,
          constructionPointZipCode,
          constructionPointRoadAddress,
          constructionPointDetailAddress,
          constructionPointBigArea,
          constructionPointSmallArea,
          constructionPointKakaoUrl,
          constructionPointStatus,
          constructionPointX,
          constructionPointY,
          constructionPointDivision,
          constructionPointLoginID,
          constructionPointPassword,
        ).then(()=>{
          alert('등록이 완료되었습니다.');
          window.location.href = "/AdminOCPointManagement";
        });
      }else{
        //파라미터가 없으면 업데이트 한다.
        update_construction_point(
          construction_point_ID_param,
          constructionPointName,
          constructionPointRepresentative,
          constructionPointPhoneNumber,
          constructionPointZipCode,
          constructionPointRoadAddress,
          constructionPointDetailAddress,
          constructionPointBigArea,
          constructionPointSmallArea,
          constructionPointKakaoUrl,
          constructionPointStatus,
          constructionPointX,
          constructionPointY,
          constructionPointDivision,
          constructionPointLoginID,
          constructionPointPassword,
        ).then(()=>{
          alert('수정이 완료되었습니다.');
          window.location.href = "/AdminOCPointManagement";
        });
      }
    }else{
      alert('필수 입력값을 확인해주세요.');
    }
  }

  // useEffect는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Section/AdminHeader.js파일에 설명을 해났음
  useEffect(() => {
    //만약에 파라미터를 보내지 않으면 construction_point_ID값은 null(빈값으로 오기 떄문에)
    //아래 if조건문에서 null이 아니라면 데이터를 가져올 것이다.
    if(construction_point_ID_param !== null){
      //특정 ID의 시공점 데이터를 가져오기 위한 함수
      get_construction_point_one(construction_point_ID_param).then((result) => {
        //데이터를 가져오면 데이터를 집어넣어준다.
        setConstructionPointName(result[0].construction_point_name);
        setConstructionPointRepresentative(result[0].construction_point_representative);
        setConstructionPointPhoneNumber(result[0].construction_point_phone_number);
        setConstructionPointZipCode(result[0].construction_point_zip_code);
        setConstructionPointRoadAddress(result[0].construction_point_road_address);
        setConstructionPointDetailAddress(result[0].construction_point_detail_address);
        setConstructionPointBigArea(result[0].construction_point_big_area);
        setConstructionPointSmallArea(result[0].construction_point_small_area);
        setConstructionPointKakaoUrl(result[0].construction_point_kakao_url);
        setConstructionPointStatus(result[0].construction_point_status);
        setConstructionPointX(result[0].construction_point_x);
        setConstructionPointY(result[0].construction_point_y);
        setConstructionPointLoginID(result[0].construction_point_login_ID);
        setConstructionPointDivision(result[0].construction_point_division);
      })
    }
  },[construction_point_ID_param]);

  return (
    <H.admin_MainWrap> 
      <AdminHeader/>
      <H.admin_rightWrap>
        <H.admin_rightTitBox>
          <H.admin_rightTitInner>
            <p>시공점 관리 - 시공점 {construction_point_ID_param === null ? ' 등록 ' : ' 수정 '}</p>
            <div>
              <H.admin_addBtn
                //onClick event에 대한 내용은 src/Admin/Login.js 참고
                onClick={()=>{form_check(); }}>
                {/* 시공점 파라미터가 있는지 없는지에 따라 등록인지 수정인지 판단 */}
                {construction_point_ID_param === null ? ' 등록 ' : ' 수정 '}
              </H.admin_addBtn>
            </div>
          </H.admin_rightTitInner>
        </H.admin_rightTitBox>

        <H.admin_rightInfoBox>
          <H.admin_rightInfoInner>
            <H.admin_labelBox>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='construction_point_name'>시공점명<span> * </span></label>
              <input 
                type='text' 
                id = 'construction_point_name' 
                maxLength={50} 
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setConstructionPointName(event_data.target.value);
                }} 
                placeholder = "시공점명을 입력해 주세요."
                value={constructionPointName}
              />
              <H.admin_errorBox className="marginLeft150">{constructionPointNameError}</H.admin_errorBox>
            </H.admin_labelBox>


            <H.admin_labelBox>
              {/* 여기는 수정에서만 보임 */
              /* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='construction_point_division'>시공점 구분<span> * </span> </label>
              <select
                id='construction_point_division'
                value={constructionPointDivision}
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setConstructionPointDivision(event_data.target.value);
                }}
              >
                <option value='1'>마스터샵</option>
                <option value='2'>프로샵</option>
                <option value='3'>취급점</option>
                <option value='4'>건축필름</option>
              </select>
            </H.admin_labelBox>

            <H.admin_labelBox>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='construction_point_login_ID'>로그인 ID </label>
              <input 
                type='text' 
                id = 'construction_point_login_ID' 
                maxLength={20} 
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setConstructionPointLoginID(event_data.target.value);
                }} 
                placeholder = "로그인ID를 입력해 주세요."
                value={constructionPointLoginID}
              />
            </H.admin_labelBox>
          
            <H.admin_labelBox>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='construction_point_password'>비밀번호 </label>
              <input 
                type='password' 
                id = 'construction_point_password' 
                maxLength={20} 
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setConstructionPointPassword(event_data.target.value);
                }} 
                placeholder = "아래 비밀번호 입력 안내 확인 후 비밀번호를 입력해 주세요."
                value={constructionPointPassword}
              />
            </H.admin_labelBox>

            <H.listInfoText className="listInfoTextSpace">
              <p className="listInfoTextBold">비밀번호 입력 안내</p>
              <p>1. 수정 시 비밀번호 변경이 필요하다면 변경할 비밀번호를 입력하셔서 수정하시면 됩니다.</p>
              <p>2. 수정 시 비밀번호 변경이 필요하지 않다면 빈칸으로 수정하시면 됩니다.</p>
            </H.listInfoText>

            <H.admin_labelBox>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='construction_point_representative'>대표자<span> * </span></label>
              <input 
                type='text' 
                id = 'construction_point_representative' 
                maxLength={20} 
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setConstructionPointRepresentative(event_data.target.value);
                }} 
                placeholder = "대표자를 입력해 주세요."
                value={constructionPointRepresentative}
              />
              <H.admin_errorBox className="marginLeft150">{constructionPointRepresentativeError}</H.admin_errorBox>
            </H.admin_labelBox>


            <H.admin_labelBox>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='construction_point_phone_number'>대표번호<span> * </span></label>
              <input 
                type='text' 
                id = 'construction_point_phone_number' 
                maxLength={20} 
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setConstructionPointPhoneNumber(event_data.target.value);
                }} 
                placeholder = "대표번호를 입력해 주세요."
                value={constructionPointPhoneNumber}
              />
              <H.admin_errorBox className="marginLeft150">{constructionPointPhoneNumberError}</H.admin_errorBox>
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='country'>국가</label>
              <select
                id='country'
                value={country}
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setCountry(event_data.target.value);
                }}
              >
                <option value='kr'>한국</option>
                <option value='vn'>베트남</option>
              </select>
            </H.admin_labelBox>

            {/* 주소창은 다시 잡아야함. 다 나눠뒀는데 하나로 묶을것 */}
            <H.admin_labelBox>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='construction_point_zip_code'>우편번호<span> * </span></label>
              <input 
                type='text' 
                id = 'construction_point_zip_code' 
                placeholder = "클릭 시 주소 검색창이 나타납니다"
                value={constructionPointZipCode}
                readOnly
                // ! 느낌표는 not이라고 하는건데
                // 위에 useState 훅에서 openPostCode의 초기값은 false였습니다.
                // ! 느낌표를 붙히면 false의 not 즉 true가 되는겁니다.
                // 그럴때 사용합니다.
                // 여기서는 클릭 시 주소창이 false이면 안뜨는데 true일때는 뜨게 됩니다.
                onClick={()=>{
                  setOpenPostCode(!openPostCode)
                }}
              />
            </H.admin_labelBox>

            <H.admin_labelBox>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='construction_point_road_address'>도로명주소<span> * </span></label>
              <input 
                type='text' 
                id = 'construction_point_road_address' 
                placeholder = "클릭 시 주소 검색창이 나타납니다"
                value={constructionPointRoadAddress}
                readOnly
                // ! 느낌표는 not이라고 하는건데
                // 위에 useState 훅에서 openPostCode의 초기값은 false였습니다.
                // ! 느낌표를 붙히면 false의 not 즉 true가 되는겁니다.
                // 그럴때 사용합니다.
                // 여기서는 클릭 시 주소창이 false이면 안뜨는데 true일때는 뜨게 됩니다.
                onClick={()=>{
                  setOpenPostCode(!openPostCode)
                }}
              />
              <H.admin_errorBox className="marginLeft150">{constructionPointRoadAddressError}</H.admin_errorBox>
            </H.admin_labelBox>

            {/* openPostCode의 false 또는 true 값에 따라 주소창을 뛰울지 말지 판단한다. */}
            {openPostCode && country === 'kr' && (
              <DaumPostcode 
                //onComplete는 DaumPostCode에서 지원해주는 함수이며
                //주소검색이 완료되면 data를 주는데
                //onComplete 함수안에
                //console.log(data);를 치고 인터넷 F12창에서 확인하면
                //여러가지를 받아오는 것을 알 수 있습니다.
                onComplete={(data) => {
                  //get_x_y_coordinate는 카카오에서 제공하는 API를 활용하여
                  //도로명주소에 대한 X,Y값을 가져오는 함수입니다.
                  //console.log(result_data);를 치고 인터넷 F12창에서 확인하면
                  //도로명주소에 대한 여러가지 값을 가져오는 것을 확인할 수 있습니다.
                  if(data.addressType === 'J'){
                    alert('도로명 주소를 선택해주세요.');
                  }else{
                    get_x_y_coordinate(data.roadAddress).then(result_data=>{
                      setConstructionPointZipCode(data.zonecode); //우편번호
                      setConstructionPointRoadAddress(data.roadAddress);  //도로명주소
                      setConstructionPointDetailAddress(data.buildingName); //상세 주소
                      setConstructionPointBigArea(data.sido); //광역단체
                      setConstructionPointSmallArea(data.sigungu);  //기초단체
                      setConstructionPointX(result_data.documents[0].x); //X좌표
                      setConstructionPointY(result_data.documents[0].y); //Y좌표
                      setOpenPostCode(false); //주소창을 닫는다.
                    })
                  }
                }}  // 값을 선택할 경우 실행되는 이벤트
                autoClose={false} // 값을 선택할 경우 사용되는 DOM을 제거하여 자동 닫힘 설정
              />
            )}          
            {openPostCode && country === 'vn' && (
              <Modal
                isOpen={openPostCode}
                onRequestClose={() => setOpenPostCode(false)}
                style={{
                  overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // 어두운 배경
                    zIndex: 1000, // 모달 우선순위
                  },
                }}
                className="vn-address-modal"
              >
                <div className="vn-address-modal-header">
                  <h2>주소 검색</h2>
                  <p>베트남 주소를 입력하세요.</p>
                </div>
                <Autocomplete
                  apiKey={apiKey} // 생성한 API 키
                  onPlaceSelected={(place) => {
                    const addressComponents = place.address_components;
                    const geometry = place.geometry.location;
                    setConstructionPointZipCode('00000'); //우편번호
                    setConstructionPointRoadAddress(place.formatted_address); // 도로명 주소
                    setConstructionPointBigArea(
                      addressComponents.find((component) => component.types.includes('administrative_area_level_1'))?.long_name || ''
                    ); // 광역 단체
                    setConstructionPointSmallArea(
                      addressComponents.find((component) => component.types.includes('administrative_area_level_2'))?.long_name || ''
                    ); // 기초 단체
                    setConstructionPointX(geometry.lat()); // 위도
                    setConstructionPointY(geometry.lng()); // 경도
                    setOpenPostCode(false);
                  }}
                  options={{
                    types: ['address'], // 주소만 검색
                    componentRestrictions: { country: 'vn' }, // 베트남으로 제한
                  }}
                  placeholder="예: ED20-21 Bau Cat 7, Tan Binh, Ho Chi Minh"
                  className="vn-address-input"
                />
                <button
                  onClick={() => setOpenPostCode(false)}
                  className="vn-address-button"
                >
                  닫기
                </button>
              </Modal>
            )}
            <H.admin_labelBox>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='construction_point_detail_address'>상세주소 </label>
              <input 
                type='text' 
                id = 'construction_point_detail_address' 
                maxLength={150} 
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setConstructionPointDetailAddress(event_data.target.value);
                }} 
                placeholder = "상세주소를 입력해 주세요."
                value={constructionPointDetailAddress}
              />
            </H.admin_labelBox>
            
            <H.admin_labelBox>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='construction_point_kakao_url'>카카오맵 URL </label>
              <input 
                type='text' 
                id = 'construction_point_kakao_url' 
                maxLength={150} 
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setConstructionPointKakaoUrl(event_data.target.value);
                }} 
                placeholder = "상세 URL을 입력해 주세요."
                value={constructionPointKakaoUrl}
              />
            </H.admin_labelBox>

            <H.listInfoText className="listInfoTextSpace">
              <p className="listInfoTextBold">카카오맵 URL 사용 방법 안내</p>
              <p>1. 카카오맵에서 해당 지점을 검색합니다.</p>
              <p>2. 지도에서 표시된 지점을 클릭하여 상세정보창을 확인합니다.</p>
              <p>3. 공유하기 아이콘을 클릭 후 URL 복사를 클릭합니다.</p>
              <p>4. 공유하기 세부창에서 URL 복사를 클릭 한 후 해당 주소를 붙여넣기 합니다.</p>
              <p>ex. http://kko.to/TPgx1p2sDg</p>
            </H.listInfoText>
            {/* 파라미터에 따라 등록인지 수정인지 판단한다.
            수정일때는 사용 여부를 노출하고 아니면 노출하지 않는다. */}
            {construction_point_ID_param !== null &&  


            <H.admin_labelBox>
              {/* 여기는 수정에서만 보임 */
              /* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='construction_point_status'>사용 여부 </label>
              <select
                id='construction_point_status'
                value={constructionPointStatus}
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setConstructionPointStatus(event_data.target.value);
                }}
              >
                <option value='1'>사용</option>
                <option value='2'>미사용</option>
              </select>
            </H.admin_labelBox>
            }

          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>
      </H.admin_rightWrap>
    </H.admin_MainWrap>
  );
}

//export default에 대한 내용은 src/Admin/Section/AdminHeader.js에 설명해났습니다.
export default AdminOCPointManagementAdd;
